import formApi from "@utilities/claApiForm"
import { useQuery, useMutation } from "@tanstack/react-query"
import logger from '@utilities/logService'

const getPeriodByProjectIdAndPeriodId = async (projectId, periodId) => {
    try {
        const { data } = await formApi.get(`/planninganalytic/period/${projectId}/${periodId}`)
        return data
    } catch (err) {
        logger.error(err);
    }
}

export const useGetPeriodByProjectIdAndPeriodIdQuery = (projectId, periodId) => {
    return useQuery(
        ["GetPeriodByProjectIdAndPeriodId", projectId, periodId],
        () => getPeriodByProjectIdAndPeriodId(projectId, periodId),
        {
            enabled: !!projectId && !!periodId
        }
    )
}

const getPeriodsByProjectFormIdAndProjectId = async (projectId, projectFormId) => {
    try {
        const { data } = await formApi.get(`/planninganalytic/period/${projectId}/${projectFormId}/allformperiods`)
        return data
    } catch (err) {
        logger.error(err);
    }
}

export const useGetAllPeriodByProjectIdAndProjectFormIdQuery = (projectId, projectFormId) => {
    return useQuery(
        ["GetAllPeriod", projectId, projectFormId],
        () => getPeriodsByProjectFormIdAndProjectId(projectId, projectFormId),
        {
            enabled: !!projectId && !!projectFormId
        }
    )
}
 
const getGraphByProjectIdAndGraphId = async (projectId, graphId) => {
    try {
        const { data } = await formApi.get(`/planninganalytic/graph/${projectId}/${graphId}`)
        return data
    } catch (err) {
        logger.error(err);
    }
}

export const useGetGraphByProjectIdAndGraphIdQuery = (projectId, graphId) => {
    return useQuery(
        ["GetGraphByProjectIdAndGraphId", projectId, graphId],
        () => getGraphByProjectIdAndGraphId(projectId, graphId),
        {
            enabled: !!projectId && !!graphId
        }
    )
}

const getAllGraphByProjectIdAndProjectFormId = async (projectId, projectFormId) => {
    try {
        const { data } = await formApi.get(`/planninganalytic/graph/${projectId}/${projectFormId}/allformgraphs`)
        return data
    } catch (err) {
        logger.error(err);
    }
}

export const useGetAllGraphByProjectIdAndProjectFormIdQuery = (projectId, projectFormId) => {
    return useQuery(
        ["GetAllGraphs", projectId, projectFormId],
        () => getAllGraphByProjectIdAndProjectFormId(projectId, projectFormId),
        {
            enabled: !!projectId && !!projectFormId
        }
    )
} 

const upsertPeriod = async (projectId, projectFormId, payload) => {
    try {
        const { data } = await formApi.post(`/planninganalytic/periods/${projectId}/${projectFormId}`, payload)
        return data
    } catch (err) {
        logger.error(err);
    }
}

export const useUpsertPeriodMutation = (projectId, projectFormId) => { 
    return useMutation((payload) => upsertPeriod(projectId, projectFormId, payload))
}

const upsertGraph = async (projectId, projectFormId, payload) => {
    try {
        const { data } = await formApi.post(`/planninganalytic/graphs/${projectId}/${projectFormId}`, payload)
        return data
    } catch (err) {
        logger.error(err);
    }
}

export const useUpsertGraphMutation = (projectId, projectFormId) => {

    return useMutation((payload) => upsertGraph(projectId, projectFormId, payload) )
}