import React, { useState, useEffect } from "react";
import { sanitize } from 'dompurify';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Radio, RadioGroup, FormControl, FormControlLabel } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { useCustomHistory } from '@hooks/index';
import { CLATooltip, CLALinkPanel, CLASimpleDrawer } from "@ais/components";
import { FORM_SETTINGS, INTERNAL_CONTROLS_FORM_TYPE_ID } from '@ais/constants';
import { Flex } from "@ais/palette";
import { colors } from '@ais/theme';
import { useOthers } from "@components/Concurrency/store/users";
import VFRenderedFieldWrapper from "@components/CustomForm/VFRenderedFieldWrapper";
import { VFSectionV2, VFInnerSection } from "@components/FormView/v2/Section";
import { ShareFormButton } from '@components/ShareForm';
import InherentRiskFactors from '@components/InherentRiskFactors/InherentRiskFactors';
import { PROJECT_FORM_INSTANCE } from '@constants/index';
import { DEFICIENCY_FORM_INSTANCE } from '@constants/forms';
import { useProjectFormInstanceProviderV2 } from '@providers';
import { TailorProcedureProvider } from "@components/Forms/state"
import { ProcedureContextProvider } from '@contexts/Procedures';
import { TailorProceduresDrawerProvider } from '@contexts/TailorProcedures/TailorProceduresDrawerContext';
import ClientAccessModal from '@views/ProjectFormInstance/components/ClientAccess/ClientAccessModal';
import ConcurrentUsersBanner from "../components/Concurrency/ConcurrentUsersBanner";
import { ProjectRiskDeficienciesProvider } from '@contexts/ProjectRiskDeficienciesContext';
import { useKeyControlDrawerContext } from '@contexts/KeyControlDrawer/KeyControlDrawerContext'
import KeyControlDrawer from '@views/InternalControl/components/Sections/UnderstandingOfEntity/KeyControlDrawer/KeyControlDrawer.jsx';
import { VFSignOffV2 } from "@components/FormView/VFSignOffV2";
import { useProject } from '@services/project';
import styles from './ProjectFormInstanceV2.module.css';
import { VFSignOffProvider } from "@providers";
import { useGETProjectClientHeaderInformationByProjectId } from '@services/project';
import { useTitle } from '@hooks/useTitle';
import CLADialogForm from '@components/CLADialogForm/CLADialogForm';
import { AUDIT_SECTIONS_TITLE } from '@constants/forms/auditscoping';
import DraftProjectBanner from "@components/DraftProjectBanner/DraftProjectBanner";

const ProjectFormInstanceV2 = () => {
    const {
        projectForm = null, 
        relatedFields, 
        userId, 
        schema, 
        showConflictResolveLink, 
        actions: { 
            onLinkClick, 
            setSelectedDominantUnit,
            onSubmitUnitDominantModal, 
            openConflictDialog,
        }, 
        isProjectFinalized, 
        formModal,
        selectedDominantUnit
    } = useProjectFormInstanceProviderV2();
    const navigate = useNavigate();
    const { lastPage, setLastPage } = useCustomHistory();
    const { isOpen: isKCDOpen } = useKeyControlDrawerContext();
    const { CLIENT_ACCESS_MODAL: { MODE } } = FORM_SETTINGS.EN;
    const { projectId } = useParams();
    const { ID } = DEFICIENCY_FORM_INSTANCE.EN;
    const others = useOthers();
    const { data: projectSettings, isLoading: projectSettingsLoading } = useProject(projectId);
    const isLockedByUser = others.find((user) => user.presence.focusedId === ID && user.info.userId.toLowerCase() !== userId.toLowerCase());

    const { data: userPrefProjects } = useGETProjectClientHeaderInformationByProjectId(projectId);
    useTitle(`${userPrefProjects?.data?.AccountNumber} > ${projectForm?.projectFormName}`);

    const [isSharedToClient, setIsSharedToClient] = useState(!(projectForm && projectForm.clientProjectFormStatusId === null));
    const [shareClientModalVisble, setShareClientModalVisble] = useState({ visible: false, mode: "" })
    const [schemaSettings, setSchemaSettings] = useState([]);
    const isInternalControlForm = projectForm?.formTypeId === INTERNAL_CONTROLS_FORM_TYPE_ID;
    const handleShareClientClick = () => {
        setShareClientModalVisble({ visible: true, mode: MODE.SHARE })
    };

    const handleRemoveSharedClientClick = () => {
        setShareClientModalVisble({ visible: true, mode: MODE.REMOVE_SHARE })
    };

    const clonedSchema = JSON.parse(JSON.stringify(schema));

    useEffect(() => {    
        if (!projectSettings || projectSettingsLoading) return;

        setSchemaSettings([
            {
                section: AUDIT_SECTIONS_TITLE.INITIAL_SCOPING_CONSIDERATIONS,
                config: {
                    REPORTING_FRAMEWORKS: projectSettings.IsFrameworkEnabled,
                    TEST_OPERATING_EFFECTIVENESS: projectSettings.IsRiskAssessmentApplied,
                    PERFORMANCE_STANDARDS: projectSettings.IsPerformanceStandardEnabled,
                },
                isHidden: !projectSettings.IsFrameworkEnabled && !projectSettings.IsRiskAssessmentApplied && !projectSettings.IsPerformanceStandardEnabled
            },
            {
                section: AUDIT_SECTIONS_TITLE.SCOTABD,
                config: null,
                isHidden: !projectSettings.IsRiskAssessmentApplied
            },
            {
                section: AUDIT_SECTIONS_TITLE.COMMON_IDENTIFIED_RISKS,
                config: null,
                isHidden: !projectSettings.IsRiskAssessmentApplied
            }
        ]);
    }, [projectSettings, projectSettingsLoading]);
    
    
    function filterSchema(data, schemaSettings) {
        return data
            .filter(section => {
                const schemaSettingsItem = schemaSettings.find(s => s.section === section.title);
                return !(schemaSettingsItem?.isHidden);
            })
            .map(section => {
                const schemaSettingsItem = schemaSettings.find(s => s.section === section.title);
    
                if (schemaSettingsItem?.config) {
                    section.fields = section.fields.map(fieldGroup =>
                        fieldGroup.filter(field => schemaSettingsItem.config[field.type] !== false)
                    );
                }
    
                return section;
            });
    }
    
    const filteredSchema = filterSchema(clonedSchema, schemaSettings);

    return (
        <>
        <DraftProjectBanner projectId={projectId} />
        <div className={styles['project-form-instance']}>
            <Grid container gap="29px">
                {projectForm?.units && (
                    <Grid container gap="8px" wrap="nowrap">
                        <Grid item>
                            <div className={styles['vf-units-label']}>
                                {PROJECT_FORM_INSTANCE.EN.ASSIGNED_UNITS}
                            </div>
                        </Grid>
                        <Grid>
                            <div className={styles['vf-units']}>
                                {projectForm?.units
                                    .map((item) => item.ProjectUnitTitle)
                                    .join(' | ')}
                            </div>
                        </Grid>
                    </Grid>
                )}
                <Grid container gap="8px">
                    <Grid item xs={12}>
                        <Flex direction="row" justify="between">
                            <div className={styles['vf-form-title']}>
                                <VFRenderedFieldWrapper
                                    className={styles.field__wrapper}
                                    isLockedByUser={isLockedByUser}>
                                    {projectForm?.projectFormName}
                                </VFRenderedFieldWrapper>
                            </div>
                            <ShareFormButton
                                isInternalControlForm={isInternalControlForm}
                                isSharedToClient={isSharedToClient}
                                isProjectFinalized={isProjectFinalized}
                                projectForm={projectForm}
                                onRemoveSharedClientClick={handleRemoveSharedClientClick}
                                onShareClientClick={handleShareClientClick}
                            />
                        </Flex>
                    </Grid>
                    {projectForm?.toolTipText && (
                        <Grid>
                            <CLATooltip
                                title={
                                    <div
                                        className="ql-editor"
                                        dangerouslySetInnerHTML={{
                                            __html: sanitize(projectForm?.toolTipText, {
                                                ADD_ATTR: ['target'],
                                            }),
                                        }}
                                    />
                                }
                                placement="bottom"
                            >
                                <HelpIcon style={{ fontSize: '18px', color: colors.navy[400] }} />
                            </CLATooltip>
                        </Grid>
                    )}
                </Grid>
            </Grid>
            {
                projectForm?.formDescription && projectForm?.formTypeId !== 3 && (
                    <div className={styles['vf-form-description']}>
                        <span>{projectForm?.formDescription}</span>
                        {showConflictResolveLink ? (
                          <span 
                            onClick={openConflictDialog} 
                            className={`${styles['vf-form-description-conflict']} ${isProjectFinalized ? styles['disable-click'] : ''}`}>
                            Conflict Resolution
                          </span>
                         ) : (
                          <></>
                        )} 
                    </div>
                )
            }
            <ProjectRiskDeficienciesProvider>
                <TailorProcedureProvider>
                    <ProcedureContextProvider>
                        <TailorProceduresDrawerProvider>
                            <VFSignOffProvider>
                                {projectForm && projectForm.schema && filteredSchema.map((section, index) => {
                                    return (
                                        <VFSectionV2 key={index} section={section} isProjectFinalized={isProjectFinalized}>
                                            <VFInnerSection
                                                fields={section.fields}
                                                auditAreaId={section?.auditArea ?? null}
                                                sectionId={section.id}
                                                sectionType={section.sectionType}
                                                section={section}
                                                sectionIndex={index}
                                            />
                                        </VFSectionV2>
                                    )
                                })
                                }
                                {schema && projectForm?.formTypeId !== INTERNAL_CONTROLS_FORM_TYPE_ID && (
                                    <InherentRiskFactors projectId={projectId} projectFormId={projectForm.projectFormID} />
                                )}
                                <VFSignOffV2
                                    requiredSignOffLevel={projectForm.requiredSignOffLevel}
                                    projectFormId={projectForm.projectFormID}
                                    disabled={isProjectFinalized}
                                />
                            </VFSignOffProvider>
                        </TailorProceduresDrawerProvider>
                    </ProcedureContextProvider>
                </TailorProcedureProvider>
            </ProjectRiskDeficienciesProvider>

            <ConcurrentUsersBanner userId={userId} />
            <CLADialogForm
                onSubmit={onSubmitUnitDominantModal}
                visible={formModal.isOpen}
                title={formModal.title}
                cancelText={formModal.cancelText}
                submitText={formModal.submitText}
                message={formModal.message}
                onCancel={() => {
                const copy = lastPage;
                setLastPage('');
                if (
                    !copy ||
                    copy.includes('/project-dashboard') ||
                    navigate.length > 1
                )
                    navigate(`/project-dashboard/${projectId}`);
                else navigate(copy);
                }}
            >
                <div style={{ marginTop: '24px', marginBottom: '25px' }}>
                <FormControl
                    fullWidth
                    sx={{ flexDirection: 'row', alignItems: 'center', gap: '12px' }}
                >
                    <RadioGroup
                        name="unitlist"
                        value={selectedDominantUnit}
                        onChange={(e)=>setSelectedDominantUnit(e.target.value)}
                        column
                        sx={{
                        gap: '20px',
                        width: '100%',
                        '& label': {
                            paddingTop: '16px',
                            paddingBottom: '16px',
                            '&:not(:last-child)': {
                            borderBottom: '0.5px solid #909090',
                            },
                        },
                        }}
                        disabled={isProjectFinalized}
                    >
                        {projectForm?.units.map((option, index) => (
                        <FormControlLabel
                            key={index}
                            label={option.ProjectUnitTitle}
                            value={option.ProjectUnitID}
                            control={<Radio value={option.ProjectUnitID} />}
                            sx={{
                            '& .MuiFormControlLabel-label': {
                                fontSize: '14px',
                                color: '#565656',
                            },
                            }}
                        />
                        ))}
                    </RadioGroup>
                </FormControl>
                </div>
            </CLADialogForm>
            <CLASimpleDrawer
                anchor="right"
                isVisible={relatedFields.length > 0}
                onClose={onLinkClick}
                allowBackDropCloseClick
            >
                <CLALinkPanel
                    fields={relatedFields}
                    title="Linked Fields"
                    projectId={projectId}
                    isProjectFinalized={isProjectFinalized}
                    isV2 // to update
                //disable for signedoff
                />
            </CLASimpleDrawer>
            <KeyControlDrawer isVisible={isKCDOpen} isProjectFinalized={isProjectFinalized} />
            {shareClientModalVisble.visible &&
                <ClientAccessModal
                    visible={shareClientModalVisble}
                    setShareClientModalVisble={setShareClientModalVisble}
                    projectForm={projectForm}
                    projectId={projectId}
                    setSharedToClient={setIsSharedToClient}
                />
            }
        </div>
    </>
    )
}

export default React.memo(ProjectFormInstanceV2);