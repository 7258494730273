import { Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material';
import { colors } from '@ais/theme';
import * as Constants from '@constants/customForm/index';

const { TITLE, HEADER, VERBIAGE, CLOSE } = Constants.ADD_RISK.RISK_PERVASIVE_WARNING_MODAL;

export const AddRiskRiskIsPervasiveModal = ({ isVisible, onModalCloseClick }) => {
  return (
    <Dialog
      open={isVisible}
      sx={{
        '& .MuiDialog-paper': { borderRadius: '5px', width: '600px', padding: '40px 60px 30px 60px' }
      }}
    >
      <DialogTitle
        sx={{
          '&.MuiDialogTitle-root': {
            fontWeight: 700,
            fontSize: '21px',
            lineHeight: '23.86px'
          }
        }}
      >
        {TITLE.charAt(0).toUpperCase() + TITLE.slice(1).toLowerCase()}
      </DialogTitle>
      <DialogContent sx={{
        '&.MuiDialogContent-root': {
          py: '20px',
          px: 0
        }
      }}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '19.2px',
            marginBottom: '10px',
            color: colors.gray[600],
            textAlign: 'left',
          }}
        >
          {HEADER}
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            lineHeight: '19.2px',
            color: colors.gray[400],
            alignItems: 'left',
          }}
        >
          {VERBIAGE}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ padding: '20px 0 0 0' }}>
        <Box>
          <Button
            onClick={onModalCloseClick}
            variant="contained"
            sx={{
              '&.MuiButton-root': { px: '25px', py: '10px', fontSize: '15px', fontWeight: 500 }
            }}
          >
            {CLOSE}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};