import styles from '@components/CLANotification/CLANotification.module.css';
import closeIcon from '@assets/icon_circle_cross.svg';
import moment from 'moment';
import sanitize from 'sanitize-filename';

import { JOB_INSTANCE_STATUS } from '@constants';
import { NOTIFICATION_MESSAGES as NOTIF_MSG } from '@constants';
import { useNotificationContext } from '@providers/notifications/NotificationProvider';
import {
  downloadProjectPdfBlob,
  markNotificationAsNotified,
} from '@services/projectPdf';
import { JOB } from '@constants';

const CLANotificationItem = ({ notification, index }) => {
  const { updateNotification } = useNotificationContext();
  const { JobInstanceId, JobStatusId, JobId, ReqBody, CreatedDate, isRead } =
    notification;
  const requestBody = ReqBody ? JSON.parse(ReqBody) : '';

  /** When using, attach a JobId property, e.g. notificationNameLookup[JobId] */
  const notificationNameLookup = {
    [JOB.PDF_GENERATION]: requestBody?.ClientName
      ? sanitize(`${requestBody.ClientName}-${requestBody.ProjectName}.pdf`)
      : 'PDF',
    [JOB.UPDATE_PROJECT_METHODOLOGY_VERSION]: sanitize(
      `${requestBody?.ClientName}_${requestBody?.ProjectName}`
    ),
  };

  const downloadPdf = async () => {
    const { ProjectId } = requestBody;
    if (ProjectId) {
      await downloadProjectPdfBlob(ProjectId, notificationNameLookup[JobId]);
    }
  };

  const redirectToNotifProjectManagementPage = () => {
    const { ProjectId } = requestBody;
    if (ProjectId) {
      window.location.href = `/project-management/${ProjectId}`;
    }
  };

  const handleNotificationLinkClick = async () => {
    const { NOT_STARTED, STARTED, NOTIFIED } = JOB_INSTANCE_STATUS;

    if ([NOT_STARTED, STARTED, NOTIFIED].includes(JobStatusId)) {
      return;
    }

    await markNotificationAsNotified(JobInstanceId);

    switch(JobId) {
      case JOB.PDF_GENERATION:
        await downloadPdf();
        break;
      case JOB.UPDATE_PROJECT_METHODOLOGY_VERSION:
        redirectToNotifProjectManagementPage();
        break;
      default:
        break;
    }
  };

  /** When using, attach both the JobId, and JobStatusId properties, e.g. notificationMessageLookup[JobId][JobStatusId] */
  const notificationMessageLookup = {
    [JOB.PDF_GENERATION]: {
      [JOB_INSTANCE_STATUS.NOT_STARTED]: NOTIF_MSG.PDF_GENERATION.NOT_STARTED,
      [JOB_INSTANCE_STATUS.STARTED]: `The ${notificationNameLookup[JobId]} ${NOTIF_MSG.PDF_GENERATION.IN_PROGRESS}`,
      [JOB_INSTANCE_STATUS.COMPLETED]: `The ${notificationNameLookup[JobId]} ${NOTIF_MSG.PDF_GENERATION.COMPLETED}`,
      [JOB_INSTANCE_STATUS.FAILED]: `The ${notificationNameLookup[JobId]} ${NOTIF_MSG.PDF_GENERATION.CREATION_FAILED}`,
      [JOB_INSTANCE_STATUS.NOTIFIED]: `The ${notificationNameLookup[JobId]} ${NOTIF_MSG.PDF_GENERATION.NOTIFIED}`,
    },
    [JOB.UPDATE_PROJECT_METHODOLOGY_VERSION]: {
      [JOB_INSTANCE_STATUS.NOT_STARTED]:
        NOTIF_MSG.UPDATE_PROJECT_METHODOLOGY_VERSION.NOT_STARTED,
      [JOB_INSTANCE_STATUS.STARTED]: `${notificationNameLookup[JobId]} ${NOTIF_MSG.UPDATE_PROJECT_METHODOLOGY_VERSION.IN_PROGRESS}`,
      [JOB_INSTANCE_STATUS.COMPLETED]: `${notificationNameLookup[JobId]} ${NOTIF_MSG.UPDATE_PROJECT_METHODOLOGY_VERSION.COMPLETED}`,
      [JOB_INSTANCE_STATUS.FAILED]: `${notificationNameLookup[JobId]} ${NOTIF_MSG.UPDATE_PROJECT_METHODOLOGY_VERSION.FAILED}`,
    },
  };

  /** When using, attach a JobId property, e.g. linkLookup[JobId] */
  const linkLookup = {
    [JOB.PDF_GENERATION]:
      JobStatusId === JOB_INSTANCE_STATUS.COMPLETED ? (
        <a
          className={styles.claNotificationItemLink}
          onClick={handleNotificationLinkClick}
          href="#"
        >
          {NOTIF_MSG.PDF_GENERATION.DOWNLOAD_PDF}
        </a>
      ) : null,
    [JOB.UPDATE_PROJECT_METHODOLOGY_VERSION]:
      JobStatusId === JOB_INSTANCE_STATUS.COMPLETED ? (
        <a
          className={styles.claNotificationItemLink}
          onClick={handleNotificationLinkClick}
        >
          {NOTIF_MSG.UPDATE_PROJECT_METHODOLOGY_VERSION.CLICK_LINK}
        </a>
      ) : null,
  };

  // Handling the local state of notification i.e isRead, is notification content clicked by user isDelete, is notification deleted by user
  const handleUpdateNotification = (index, type, value) => {
    updateNotification(index, type, value);
  };

  return (
    <div style={{ display: 'flex' }}>
      <div
        className={styles.claNotificationItemHeader}
        onClick={() => handleUpdateNotification(index, 'isRead', true)}
      >
        <p className={styles.claNotificationItemDate}>
          {moment.utc(CreatedDate).format('MM/DD/YYYY')}
        </p>
        <p
          className={styles.claNotificationItemTextContent}
          style={{ fontWeight: isRead ? '400' : '600' }}
        >
          {notificationMessageLookup[JobId][JobStatusId]}
        </p>
        {linkLookup[JobId]}
      </div>
      <div style={{ padding: '5px' }} role="button">
        <img
          className={styles.closeIcon}
          src={closeIcon}
          alt="closeIcon"
          onClick={() => handleUpdateNotification(index, 'isDelete', true)}
        />
      </div>
    </div>
  );
};

export default CLANotificationItem;
