export const SIGN_OPTIONS = {
    ACTUAL_SIGN: "Actual Sign",
    REVERSE_SIGN: "Reverse Sign"
}

export const TYPE_TYPES = {
    ACCOUNT: "account",
    ACCOUNT_SUB_TYPE: "accountSubType",
    FS_CAPTION: "fsCaption",
    ACCOUNT_NAME: "glAccountName",
    GL_ACCOUNT_NUMBER: "glAccountNumber"
}

export const TYPE_MENU_ITEMS = [
    {
        value: TYPE_TYPES.ACCOUNT,
        label: "Type"
    },
    {
        value: TYPE_TYPES.ACCOUNT_SUB_TYPE,
        label: "Classification"
    },
    {
        value: TYPE_TYPES.FS_CAPTION,
        label: "Category"
    },
    {
        value: TYPE_TYPES.ACCOUNT_NAME,
        label: "Sub Category"
    },
    {
        value: TYPE_TYPES.GL_ACCOUNT_NUMBER,
        label: "Account"
    }
]

export const FUND_LEVEL_MENU_ITEMS = [
    {
        value: "none",
        label: "None"
    },
    {
        value: "fundType",
        label: "Fund Type"
    },
    {
        value: "fundSubType",
        label: "Fund SubType"
    },
    {
        value: "fund",
        label: "Fund"
    }
]

export const GetAnswerFromTrialBalanceSupportingValue = (value) => {
    return IsAnswerTrialBalance(value) ? JSON.parse(value).amount ?? "No Data Available" : value ?? '';
}

export const GetCorrelationNameIdsFromTrialBalance = (value) => {
    return IsAnswerTrialBalance(value) ? JSON.parse(value).correlationNameIds : [];
}

export const GetTrialBalanceFromTrialBalanceSupportingValue = (value) => {
    return IsAnswerTrialBalance(value) ? JSON.parse(value) : {};
}

export const IsAnswerTrialBalance = (value) => {
    //To avoid excessive exceptions, check if the string value contains object-like syntax before attempting to parse it for validation
    //First if check to prevent attempts to trim undefined during load
    if (typeof value !== 'string') return false;
    if (value.trim().startsWith('{') && value.trim().endsWith('}')) {
        try {
            return Object.keys(JSON.parse(value)).includes('correlationDetailIds');
        } catch {
            return false;
        }
    } else {
        return false;
    };
}

export const FilterValidTrialBalances = (trialBalanceData) => {
    return trialBalanceData.filter(
        tb => !!tb.correlationDetailId && !!tb.correlationName && !!tb.correlationNameId
    );
}

export const FilterTrialBalances = (trialBalanceData, answers) => {
    //Only shows unique tbs based on their correlationNameId. 
    //We prioritize any previous saved answer in case of duplicates so our UI state properly matches.
    const filterUniqueTb = (uniqueTbs, currentTb) => {
        if(uniqueTbs.has(currentTb.correlationNameId)){
            if(answers.includes(currentTb.id)){
                uniqueTbs.set(currentTb.correlationNameId, currentTb);
            }
        }else{
            uniqueTbs.set(currentTb.correlationNameId, currentTb);
        }
        return uniqueTbs;
    }

    const uniqueTrialBalances = [...trialBalanceData.reduce(filterUniqueTb, new Map()).values()];
    const noNullsUniqueTrialBalances = uniqueTrialBalances.filter(
        tb => !!tb.correlationDetailId && !!tb.correlationName && !!tb.correlationNameId
    )
    return noNullsUniqueTrialBalances;
}

export const EvaluateFundIndexOptions = (value) => {
    switch (value) {
        case 'fund':
            return 'segment03';
        case 'fundType':
            return 'segment04';
        case 'fundSubType':
            return 'segment05';
        default:
            return '';
    }
}

export const DynamicallyUpdateAnswerPayload = (trialBalanceAnswerData, detailedTrialBalanceData) => {
    const updatedAnswers = [];

    trialBalanceAnswerData.forEach(answerData => {
        const parsedAnswer = GetTrialBalanceFromTrialBalanceSupportingValue(answerData.answer);
        const savedSelections = getSelectedSavedData(parsedAnswer);
        const updatedAnswer = ConstructAnswerPayload(savedSelections, detailedTrialBalanceData);

        updatedAnswers.push({ ...answerData, answer: JSON.stringify(updatedAnswer), updated: updatedAnswer.amount !== parsedAnswer.amount });
    });

    return updatedAnswers;
}

const getSelectedSavedData = (answer) => {
    const selectedData = {
        "correlationNameIds": answer?.correlationNameIds,
        "type": answer?.type,
        "groupAccount": answer?.groupAccount,
        "period": answer?.period,
        "balanceType": answer?.balanceType,
        "fundLevel": answer?.fundLevel,
        "fundIndex": answer?.fundIndex,
        "signOptions": answer?.signOptions,
        "correlationDetailIds": answer?.correlationDetailIds,
        "amount": answer?.amount
    }

    return selectedData;
}

export const ConstructAnswerPayload = (trialBalanceRawData, detailedTrialBalanceData) => {
    trialBalanceRawData.amount = CalculateAnswerSum(trialBalanceRawData, detailedTrialBalanceData);
    return trialBalanceRawData;
}

//FILTERS (Selected Trial Balances, Group/Account/Type, Period, Balance Type, (If Applicable Fund Level Info))
const CalculateAnswerSum = (trialBalanceRawData, detailedTrialBalanceData) => {
    //Dates for filtering logic only (will not be bound, Engine B dates are formatted differently)
    const [startDate, endDate] = trialBalanceRawData.period.replaceAll(' ', '').split('-').map(FormatTbPeriodDate);

    //This gets the trial balances selected in the dropdown and their variancy (TB 1 unadjusted, TB 1 final (which will have same correlationNameId since it's from same engagement file)
    //We then examine the dynamic filters the user selects, apply them, and then summate the final result
    const selectedTrialBalancesCorrelationNameIds = detailedTrialBalanceData.filter(tb => trialBalanceRawData.correlationNameIds.includes(tb.id)).map(tb => tb.correlationNameId);

    //Filter selected TBs, period and balance type for TB scope
    const selectedDetailedTrialBalances = detailedTrialBalanceData.filter(tb =>
        selectedTrialBalancesCorrelationNameIds.includes(tb.correlationNameId) &&
        tb.trialBalanceType === trialBalanceRawData.balanceType &&
        tb.startDate === startDate &&
        tb.endDate === endDate
    );

    //Apply dynamic filters for group/account type
    const selectedDetailedTrialBalancesGroups = selectedDetailedTrialBalances.flatMap(tb => tb.groups);
    const applicableSummationItems = FilterByGroupingType(trialBalanceRawData, selectedDetailedTrialBalancesGroups);

    //If fund applies, apply fund level filter and summate, otherwise summate all applicable items
    if (trialBalanceRawData.fundIndex) {
        const targetKey = EvaluateFundIndexOptions(trialBalanceRawData.fundLevel);
        const applicableFundLevelItems = applicableSummationItems.filter(group => group[targetKey] === trialBalanceRawData.fundIndex);

        const sum = applicableFundLevelItems.length > 0 ? applicableFundLevelItems.map(group => group.amountEnding).reduce((sum, number) => sum + number) : 0;
        return trialBalanceRawData.signOptions === SIGN_OPTIONS.ACTUAL_SIGN ? sum : sum * -1;
    } else {
        const sum = applicableSummationItems.length > 0 ? applicableSummationItems.map(group => group.amountEnding).reduce((sum, number) => sum + number) : 0;
        return trialBalanceRawData.signOptions === SIGN_OPTIONS.ACTUAL_SIGN ? sum : sum * -1;
    }
}

const FilterByGroupingType = (trialBalanceRawData, selectedDetailedTrialBalancesGroups) => {
    //dynamic filters need to be evaluated differently for different situations.
    //For example glAccountNumber would need to splice and check if first part of array matches account number and second part matches glAccountName
    //Some names will contain multiple spaces, ensure only accountNumber prefix is stripped with this logic
    if (trialBalanceRawData.type === TYPE_TYPES.GL_ACCOUNT_NUMBER) {
        const [accountNumber, ...accountNameParts] = trialBalanceRawData.groupAccount.split(' ');
        const accountName = accountNameParts.join(' ');
        return [...selectedDetailedTrialBalancesGroups].filter(group =>
            group[trialBalanceRawData.type] === accountNumber && group[TYPE_TYPES.ACCOUNT_NAME] == accountName
        );
    } else {
        //All other cases currently supported (7.0.0) are single filter types
        return [...selectedDetailedTrialBalancesGroups].filter(group =>
            group[trialBalanceRawData.type] === trialBalanceRawData.groupAccount
        );
    }
}

//Format to match engine B format to avoid date parsing issues when filtering period data for summation
export const FormatTbPeriodDate = (date) => {
    const [month, day, year] = date.split('/');
    return `${year}-${month}-${day}`;
}