import React from 'react';
import { styled } from '@mui/system';
import { useFormContext } from 'react-hook-form';

import { FIELD_TYPES } from '@ais/constants';
import styles from '@components/FormView/FormView.module.css';
import { useUpdateMyPresence } from '@components/Concurrency/provider/RoomProvider';
import { useOthers } from '@components/Concurrency/store/users';
import VFRenderedFieldWrapper from '@components/CustomForm/VFRenderedFieldWrapper';
import useProjectContext from '@contexts/ProjectContext';
import { useFinalizedProjectNoId, useLocking } from '@hooks';
import {
  useProjectFormInstanceProviderV2,
  useVFSignOffProvider,
} from '@providers';
import { CLATrialBalanceSelect } from '@views/ProjectScopingForm';
import CPPPTable from '../../../views/PlanningAnalytics/components/CPPPTable/CPPPTable';
import GLCAccounts from '../../../views/PlanningAnalytics/components/GLCAccounts/GLCAccounts';
import GraphsDisplayManager from '../../../views/PlanningAnalytics/components/Graphs/GraphsDisplayManager';
import { usePlanningAnalytics } from '@views/PlanningAnalytics';
import useRules from './hooks/useRules';

const StyledDiv = styled('div')({ padding: '0 24px' });
const StyledVFRenderedFieldTBWrapper = styled(VFRenderedFieldWrapper)({
  padding: '0',
});

export const PlanningAnalyticsFieldSelector = ({ field, section }) => {
  const { id, formTypeId, type } = field;
  const {
    answerList,
    projectForm: { projectFormID, schema },
    userId,
    actions: { onSubmit, onFocus },
  } = useProjectFormInstanceProviderV2();
  const { signOffUserList } = useVFSignOffProvider();

  const { shouldDisplay } = useRules(field.rules, answerList);
  const isProjectFinalized = useFinalizedProjectNoId();
  const { setValue, getValues } = useFormContext();
  const isLockedByUser = useLocking(id, userId);
  const others = useOthers();
  const { project } = useProjectContext();
  const updateMyPresence = useUpdateMyPresence();
  const disabled = isProjectFinalized || isLockedByUser !== undefined;
  const isTrialBalanceFieldLock = (ids) => {
    return !!others.find(
      (user) =>
        ids.includes(user.presence.focusedId) &&
        user.info.userId.toLowerCase() !== userId.toLowerCase()
    );
  };

  const {
    isTrendingByMonthExpanded,
    actions: { updateIsLoadCurrentPeriodSection },
  } = usePlanningAnalytics();

  if (!shouldDisplay) return <></>;

  const renderFields = () => {
    switch (type) {
      case FIELD_TYPES.TRIAL_BALANCE: {
        const value = getValues(id);
        const defaultArrayValue = Array.isArray(value)
          ? value
          : value
            ? [value]
            : [];
        const isLock = isTrialBalanceFieldLock([id]);
        return (
          <StyledVFRenderedFieldTBWrapper
            className={styles.field__wrapper}
            isLockedByUser={isLockedByUser}
            isLocked={isLockedByUser}
          >
            <div className={styles.field__wrapper_inner}>
              <CLATrialBalanceSelect
                defaultValues={defaultArrayValue}
                formTypeId={formTypeId}
                handCaptureInput={(userInput) => {
                  setValue(id, userInput);
                  onSubmit(id);
                }}
                field={field}
                isDisabled={disabled || isLock}
                project={project}
                onFocus={onFocus}
                signOffList={signOffUserList}
                highlightable={true}
                isPlanningAnalyticsForm={true}
              />
            </div>
          </StyledVFRenderedFieldTBWrapper>
        );
      }
      case FIELD_TYPES.GENERAL_LEDGER: {
        return (
          <StyledDiv className={styles.field__wrapper_noalign}>
            <GLCAccounts
              isInstance={true}
              disabled={false}
              appDataInstanceId={project?.AppDataInstanceId}
              setIsLoadCurrentPeriodSection={updateIsLoadCurrentPeriodSection}
              schema={{ properties: schema }}
            />
          </StyledDiv>
        );
      }
      case FIELD_TYPES.CURRENT_PERIOD_TO_PRIOR: {
        return (
          <StyledDiv className={styles.field__wrapper_noalign}>
            <div style={{ marginTop: '50px' }}>
              <CPPPTable
                projectId={project.id}
                sectionId={section?.id}
                section={section}
                schema={{ properties: schema }}
                userId={userId.toUpperCase()}
              />
            </div>
          </StyledDiv>
        );
      }
      case FIELD_TYPES.TRENDING_BY_MONTH: {
        return (
          <StyledDiv className={styles.field__wrapper_noalign}>
            <GraphsDisplayManager
              projectId={project?.id}
              projectFormId={projectFormID}
              sectionId={section?.id}
              project={project}
              fieldId={id}
              isLockedByUser={isLockedByUser}
              isLocked={isLockedByUser && isTrendingByMonthExpanded}
              handleLock={() => {
                updateMyPresence({ focusedId: id });
              }}
              handleUnlock={() => {
                updateMyPresence({ focusedId: null });
              }}
              schema={{ properties: schema }}
              userId={userId.toUpperCase()}
            />
          </StyledDiv>
        );
      }
      case FIELD_TYPES.KPIS_RATIOS: {
        return (
          <></>
          // TODO: Enable once KPI's are implemented
          // Disabled based on US-3181 AC 8
          // <div className={styles.field__wrapper_noalign}>
          //     <div className={styles.field__wrapper_inner}>
          //         <div className={styles['vf-section-placeholder']}>
          //             KPIS RATIOS Component Placeholder
          //         </div>
          //     </div>
          //     {renderLink()}
          // </div>
        );
      }
      default:
        return <></>;
    }
  };

  return <div style={{ width: '100%' }}>{renderFields()}</div>;
};
