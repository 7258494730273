import { CLANestedDataGrid } from '@ais/components';
import { useParams } from 'react-router-dom';
import { useFormContext } from "react-hook-form"
import styles from './CPPPTable.module.css';
import React, { useState, useEffect } from "react";
import { Comment, CommentResolved } from '@ais/assets';
import { Badge, Box, IconButton } from '@mui/material/';

import GridStyle from "./styles.js"
import CPPPCategoryLevel from '../CPPPTable/CPPPCategoryLevel/CPPPCategoryLevel';
import { useCurrentPeriodComparison } from '@services/trialbalance';
import { getTrialBalanceValues } from '@views/PlanningAnalytics/components/helpers.js';
import { formatMoneyDecimalAndComma, formatDecimalToPercent } from "@utilities/numberHelper";
import { useDisabledGlobalQueryLoading } from "@hooks/index";
import { usePlanningAnalytics } from '../../hooks/usePlanningAnalytics.js';
import { useFinalizedProject } from '@hooks/useProject';
import { useVFSignOffProvider } from '@providers/index.js';
import { useProjectFormComments } from '@services/forms/projectFormComments.js';
import { sortReviewerSignoffAsc } from '@utilities/dateHelpers.js';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';

const CPPPTable = ({ sectionId, section, schema, userId }) => {
  const [rows, setRows] = useState([])
  const [expandedRow, setExpandedRow] = useState([])
  const [trialBalances, setTrialBalances] = useState([]);
  const { projectId, projectFormId } = useParams();
  const [isLoading, setIsLoading] = useState(true)

  const formCanvas = useFormContext();

  const isProjectFinalized = useFinalizedProject(projectId);
  const { signOffUserList } = useVFSignOffProvider();
  const {
    isLoadCurrentPeriodSection,
    concurrencyEventReceived,
    comment: concurrencyComment
  } = usePlanningAnalytics() 
  const { data: projectFormComments, refetch } = useProjectFormComments(projectFormId, trialBalances, projectId)

  useDisabledGlobalQueryLoading()

  useEffect(() => {
    return () => {
      setRows([])
      setExpandedRow([])
    }
  }, [])

  useEffect(() => { 
    refetch()
  }, [JSON.stringify(trialBalances)])

  useEffect(() => {
    const getTrialBalance = getTrialBalanceValues(schema, formCanvas.getValues());

    if (!getTrialBalance)
      return;

    const _trialBalanceIds = getTrialBalance.map(data => data.correlationDetailId);
    setTrialBalances(_trialBalanceIds);
  }, [formCanvas])

  const {
    data: currentPeriodComparison,
    isLoading: isLoadingCurrentPeriodComparison,
    isRefetching,
    isSuccess,
    status
  } = useCurrentPeriodComparison(projectFormId, trialBalances, projectId);

  useEffect(() => {
    setIsLoading(true)
  }, [JSON.stringify(trialBalances)])

  useEffect(() => {
    if (status === 'error' && !isSuccess) {
      setIsLoading(false);
      return;
    }
  }, [isSuccess, status])

  useEffect(() => {
    if (!isLoadCurrentPeriodSection && !concurrencyEventReceived) return; 
    if (currentPeriodComparison && currentPeriodComparison?.status === 200) {
      if (currentPeriodComparison.data.length < 1) {
        setRows([])
        setIsLoading(false)
        return
      }
      const projectFormCommentsData = projectFormComments?.data ?? [];
      const _dataCopy = [...currentPeriodComparison.data]
      _dataCopy.forEach((item, index) => {
        const { CategoryLevelData } = item;
        const comment = projectFormCommentsData.find(projectFormComment => {
          const CommentPath = projectFormComment?.CommentPath

          const _commentPath = JSON.parse(CommentPath ?? "[]");
          const trialBalanceCondition = Array.isArray(_commentPath?.trialBalances)
            ? _commentPath?.trialBalances.map(x => x.trialBalanceId).includes(item.CorrelationDetailId)
            : _commentPath?.trialBalances.trialBalanceId === item.CorrelationDetailId
          if (_commentPath['TypeName'] && _commentPath['TypeName'] === item.TypeName && trialBalanceCondition) {
            return true;
          }
        }) 
        if (comment) {
          const latestReviewersAsc = sortReviewerSignoffAsc(signOffUserList)
          const dateModified = new Date(comment.ModifiedDate).getTime();
          const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);

          item.shouldHighlight = isSignoffEnabled && (new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified);
        }
        item.id = index
        item.ExpandedPanel =
          <CPPPCategoryLevel
            rowData={CategoryLevelData}
            userId={userId}
            sectionId={sectionId}
            isCommentSectionExpanded={false}
            commentId={item.ProjectFormCommentId}
            comment={item.Comment}
            section={section}
            commentPath={{
              trialBalances: [{
                trialBalanceId: item.CorrelationDetailId,
                trialBalanceName: item.CorrelationName,
              }],
              TypeName: item.TypeName,
              ProjectFormId: projectFormId
            }}
            disabled={isProjectFinalized}
            refetchFormComment={refetch}
          />
        _dataCopy[index] = item
      })

      setRows(_dataCopy);
      setIsLoading(false)
    }

    return () => {
      setRows([])
    }
  }, [currentPeriodComparison?.data, isLoadingCurrentPeriodComparison, isRefetching, isLoadCurrentPeriodSection, concurrencyEventReceived, signOffUserList, projectFormComments])

  useEffect(() => { 
    if (currentPeriodComparison && currentPeriodComparison?.status === 200 && concurrencyEventReceived) {
      if (currentPeriodComparison.data.length < 1) {
        setRows([])
        setIsLoading(false)
        return
      }
      const _dataCopy = [...currentPeriodComparison.data]

      _dataCopy.forEach((item, index) => {
        const { CategoryLevelData } = item;
        let comment = item;
        const commentPath = {
          trialBalances: [{
            trialBalanceId: comment.CorrelationDetailId,
            trialBalanceName: comment.CorrelationName,
          }],
          TypeName: comment.TypeName,
          ProjectFormId: projectFormId
        }
        
        if (item.ProjectFormCommentId === concurrencyComment.ProjectFormCommentId || concurrencyComment.CommentPath === JSON.stringify(commentPath))
          comment = {
            ...comment,
            ProjectFormCommentId: concurrencyComment.ProjectFormCommentId,
            Comment: concurrencyComment.Comment
          }
        comment.id = index
        comment.ExpandedPanel =
          <CPPPCategoryLevel
            rowData={CategoryLevelData}
            userId={userId}
            sectionId={sectionId}
            isCommentSectionExpanded={true}
            commentId={comment.ProjectFormCommentId}
            comment={comment.Comment}
            commentPath={commentPath}
            section={section}
            disabled={isProjectFinalized}
            refetchFormComment={refetch}
          />
        _dataCopy[index] = comment
      })

      setRows(_dataCopy);
      setIsLoading(false)
    }
  }, [concurrencyEventReceived, concurrencyComment])

  const handleAdditionalCollapseTrigger = (newIds) => {
    const rowId = newIds.slice(-1);
    setExpandedRow(rowId)
  }

  const handleCommentToggle = rowId => {
    const index = rows.findIndex(item => item.id === rowId);
    const row = rows[index];

    row.isCommentSectionExpanded = !row.isCommentSectionExpanded;
    row.ExpandedPanel =
      <CPPPCategoryLevel
        rowData={row.CategoryLevelData}
        isCommentSectionExpanded={row.isCommentSectionExpanded}
        userId={userId}
        sectionId={sectionId}
        commentId={row?.ProjectFormCommentId}
        comment={row.Comment}
        section={section}
        commentPath={{
          trialBalances: [{
            trialBalanceId: row.CorrelationDetailId,
            trialBalanceName: row.CorrelationName,
          }],
          TypeName: row.TypeName,
          ProjectFormId: projectFormId
        }}
        disabled={isProjectFinalized}
        refetchFormComment={refetch}
      />
    setRows((prevState) => {
      const _rowsCopy = [...prevState];
      _rowsCopy.forEach(item => {
        if (item.id !== rowId) {
          item.isCommentSectionExpanded = false
        }
      })
      _rowsCopy[index] = row;

      return _rowsCopy;
    })
    expandedRow.push(rowId)
    handleAdditionalCollapseTrigger(expandedRow)
  }

  const columns = [
    {
      field: 'TypeName',
      headerName: 'Type',
      flex: 1,
      minWidth: 280,
      maxWidth: 280,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
    },
    {
      field: 'CurrentPeriodBalance',
      flex: 1,
      headerName: 'Current Period Unadjusted',
      headerClassName: styles["cppp-header-select"],
      editable: false,
      sortable: false,
      maxWidth: 200,
      minWidth: 200,
      filterable: false,
      disableReorder: true,
      disableColumnMenu: true,
      renderHeader: _ => {
        return (
          <>
            <div className={`${styles["cppp-header-container"]} ${styles["cppp-header-container-difference"]}`}>
              <div>
                <strong>Current Period</strong>
              </div>
              <strong>Unadjusted</strong>
            </div>
          </>
        )
      },
      renderCell: params => {
        if(params?.row?.CurrentPeriodBalance == null) return ''
        return formatMoneyDecimalAndComma(params?.row?.CurrentPeriodBalance)
      }
    },
    {
      field: 'PriorPeriodBalance',
      flex: 1,
      headerName: 'Prior Period Final',
      disableColumnMenu: true,
      hideSortIcons: true,
      maxWidth: 200,
      minWidth: 200,
      editable: false,
      sortable: false,
      filterable: false,

      headerClassName: styles["cppp-header-select"],
      disableReorder: true,
      renderCell: params => {
        if(params?.row?.PriorPeriodBalance == null) return ''
        return formatMoneyDecimalAndComma(params?.row?.PriorPeriodBalance)
      }
    },
    {
      field: 'DifferenceAmount',
      flex: 1,
      editable: false,
      maxWidth: 200,
      minWidth: 200,
      sortable: false,
      filterable: false,
      disableReorder: true,
      renderHeader: () => {
        return (
          <div className={`${styles["cppp-header-container"]} ${styles["cppp-header-container-difference"]}`}>
            <div>
              <strong>Difference</strong>
            </div>
            <strong>($)</strong>
          </div>
        )
      },
      renderCell: params => {
        if(params?.row?.DifferenceAmount == null) return ''
        return formatMoneyDecimalAndComma(params?.row?.DifferenceAmount)
      }
    },
    {
      field: 'DifferencePercent',
      flex: 1,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      renderHeader: () => {
        return (
          <div className={`${styles["cppp-header-container"]} ${styles["cppp-header-container-difference"]}`}>
            <div>
              <strong>Difference</strong>
            </div>
            <strong>(%)</strong>
          </div>
        )
      },
      renderCell: params => {
        if(params?.row?.DifferencePercent == null) return ''
        return formatDecimalToPercent(params?.row?.DifferencePercent)
      }
    },
    {
      field: 'Comment',
      flex: 1,
      headerName: 'Comments',
      editable: false,
      sortable: false,
      minWidth: 150,
      maxWidth: 150,
      filterable: false,
      disableReorder: true,
      renderCell: (params) => {
        const row = params.row;
        const _hasComment = row.Comment;
        const shouldHighlight = row.shouldHighlight;
        return (
          <Box sx={shouldHighlight ? { borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red', p: '4px' } : {}}>
            <Badge
              variant="comment"
            >
              <IconButton
                sx={{ padding: 0 }}
                onClick={() => handleCommentToggle(params?.row?.id)}
              >
                {(_hasComment) ? <CommentResolved /> : <Comment />}
              </IconButton>
            </Badge>
          </Box>
        )
      }
    },
  ];

  return (
    <CLANestedDataGrid
      sx={{...GridStyle}}
      rows={rows}
      columns={columns}
      rowHeight={50}
      handleAdditionalCollapseTrigger={handleAdditionalCollapseTrigger}
      expandedRow={expandedRow}
      hideFooterRowCount={true}
      showFooterContent={false}
      hideFooterPagination={true}
      headerHeight={80}
      hideFooter={true}
      loading={isLoading}
    /> 
  )
}

export default CPPPTable
