export const TRIAL_BALANCE = {
  EN: {
    ID: 'trial-balance',
    TRIAL_BALANCE: 'Trial Balance',
    PLACEHOLDER: 'Select',
    PLACEHOLDER_WITH_OPTIONAL: 'Select (Optional)',
    AUDIT_AREA: 'Audit Area',
    FINANCIAL_STATEMENT: 'Financial Statement Line Items',
    SCOTABDS:
      'Significant Classes of Transactions, Account Balances and Disclosure',
    CATEGORY: 'Category',
    BALANCE: 'Balance',
    NUMBER_OF_TRANSACTION: 'Number of Transactions',
    ACTUAL_SIGN: 'actual sign',
    UNADJUSTED: 'Unadjusted',
  },
  BUTTON: {
    AUDIT_AREA: 'ADD AN AUDIT AREA',
    FINANCIAL_STATEMENT: 'ADD FINANCIAL STATEMENT LINE ITEMS',
    CUSTOM_FINANCIAL_STATEMENT: 'ADD CUSTOM FINANCIAL STATEMENT LINE',
    SCOTABDS: 'ADD SCOTABDS',
    CANCEL: 'Cancel',
    SAVE: 'Save',
  },
};
