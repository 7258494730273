export const COMMON = {
    EN: {
        BUTTON: {
            BACK: "Back",
            SAVE: "Save",
            SAVENEXT: "Save & Next",
            CANCEL: "Cancel",
            CLOSE: "Close",
            OK: "OK",
            CLEAR: "Clear",
            SUBMIT: "Submit",
            ADD: "Add",
            DELETE: "Delete",
            CONFIRM: "Confirm",
            APPROVE: "Approve",
            UNAPPROVE: "Unapprove",
            UPDATE: 'Update'
        },
        MODAL_HEADER: {
            WARNING: "Warning",
            CONFIRMATION: "Confirmation"
        },
        MODAL_BODY: {
            DELETE_MESSAGE: "Are you sure you want to delete this? It will permanently delete the data."
        },
        OPTIONS: {
            YES: "Yes",
            NO: "No",
        },
        OTHERS: {
            HIGHER: "Higher",
            LOWER: "Lower",
            SELECT: "Select",
            FORM: "Form",
            SECTION: "Section"
        },
        ERROR_SELECT_UNIT: "Select at least one unit"
    }
};

export const DATE_YEAR_FORMAT = "yyyy"

export const DATETIME_FORMAT = "MM/dd/yyyy hh:mm bb"

export const DATE_FORMAT = "MM/dd/yyyy"

export const PROJECT_STATE = {
    ACTIVE: 1,
    FINALIZED: 2,
    DRAFT: 3
}

export const ERRORS = {
    NOT_LOGGED_IN: 'NotLoggedInError'
}