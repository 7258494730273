import { useEffect, useState, useRef } from 'react';
import styles from '@components/FormView/FormView.module.css';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { CLAPerson } from '@ais/components';
import { useUserList } from '@hooks/index';

const VFRenderedFieldWrapperToolTip = styled(({ className, children, ...props }) => {
    const popperRef = useRef(null);
    const observerRef = useRef(null);

    useEffect(() => {
        const targetElement = document.querySelector("#cla-data-grid-container"); 
        if (!targetElement) return;

        observerRef.current = new MutationObserver(() => {
            if (popperRef.current?.update) {
                popperRef.current.update(); // Force tooltip to reposition
            }
        });

        observerRef.current.observe(targetElement, { attributes: true, childList: true, subtree: true });

        return () => observerRef.current?.disconnect(); 
    }, []);

    return (
        <Tooltip
            {...props}
            arrow
            classes={{ popper: className }}
            PopperProps={{
                popperRef,
                disablePortal: props.disablePortal ?? false,
                popperOptions: {
                    strategy: "fixed", 
                    modifiers: [
                        {
                            name: "preventOverflow",
                            options: { boundary: "window" },
                        },
                        {
                            name: "flip",
                            options: { fallbackPlacements: ["top", "bottom"] },
                        },
                        {
                            name: "computeStyles",
                            options: {
                                adaptive: true, 
                            },
                        },
                        {
                            name: "eventListeners",
                            options: { scroll: true, resize: true },
                        },
                    ],
                },
            }}
        >
            {children}
        </Tooltip>
    );
})((styles) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#ffd7d4",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#ffd7d4",
        color: "#bc2142",
        fontWeight: "400",
        fontSize: ".6rem",
        maxWidth: 500,
        ...styles.customTooltipStyle,
    },
}));

export const VFRenderedFieldWrapper = ({ children, isLockedByUser, isLocked = true, ...props }) => {
    const [account, setAccount] = useState();
    const { getUserById } = useUserList();

    useEffect(() => {
        const getUserData = async () => {
            const data = await getUserById(isLockedByUser?.info?.userId);
            setAccount(data);
        }

        getUserData();
    }, [isLockedByUser])


    const renderTitle = () => {
        return (<>
            <div className={styles.field_wrapper_locked_tooltip_title}>
                <span style={{ marginRight: '1rem' }}>
                    <CLAPerson
                        styles={{
                            height: '40px',
                            width: '40px'
                        }}
                        account={account}
                        personCardInteraction='none'
                        className={styles.person}
                    />
                </span>
                <p style={{ margin: 'auto', padding: '.1rem' }}>{isLockedByUser?.info.name} is currently<br />editing this item</p>
            </div>
        </>)
    }

    const renderLockedToolTip = () => {
        return (
            <VFRenderedFieldWrapperToolTip
                title={renderTitle()}
                open={isLockedByUser?.info.userId && !!isLocked ? true : false}
                customTooltipStyle={props.customTooltipStyle || {}}
                placement="top"
                {...props}
            >
                <div style={{ pointerEvents: 'none' }}>
                    <div className={props?.className} style={props?.style}>
                        {children}
                    </div>
                </div>
            </VFRenderedFieldWrapperToolTip>)
    }

    return (
        <>
            {isLockedByUser && isLockedByUser?.info?.userId ? <>{renderLockedToolTip()}</> :
                <div className={props?.className} style={props?.style}>{children}</div>}
        </>
    )
}

export default VFRenderedFieldWrapper;