import style from "./DraftProjectBanner.module.css";
import { Button } from '@mui/material';
import * as Constants from '@constants';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useProject } from '@services/project';
import { useNavigate } from "react-router-dom";
import { useLoading } from '@hooks/index';
import { useRefreshFormsForDraftProject } from '@services/forms/projectforms.js'

const DraftProjectBanner = ({
    projectId,
}) => {
    const setLoading = useLoading();
    const { DRAFT_PROJECT_BANNER: {
        EN: { METHODOLOGY_MANAGEMENT_LABEL, REFRESH_LABEL}
    } } = Constants;
    const { data: projectData } = useProject(projectId);
    const { mutateAsync } = useRefreshFormsForDraftProject(projectId);
    const navigate = useNavigate();
    const handleRefresh = async () => {
        try{
            setLoading(true);
            await mutateAsync(projectId);
            navigate(0);
        } catch(e){
            console.error(e);
        } finally {
            setLoading(false);
        }
    }
    if(!projectData || projectData.ProjectStateId !== 3 ) return null;
    return(
    <div className={style.bannerContainer}>
        <div>
            <strong>You are previewing {projectData.ProjectName} </strong>
        </div>
        <div className={style.buttonWrapper}>
            <Button
                variant="contained"
                className={style.buttons}
                onClick={() => navigate("/methodology", {
                    state: { isDraft: true }
                })}
            >
                {METHODOLOGY_MANAGEMENT_LABEL}
            </Button>
            <Button
                variant="contained"
                startIcon={<RefreshIcon />}
                className={style.buttons}
                onClick={handleRefresh}
            >
                {REFRESH_LABEL}
            </Button>
        </div>
    </div>  
)}

export default DraftProjectBanner;