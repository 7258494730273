import { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { useMsal } from '@azure/msal-react';
import { stringHelper as string } from '@ais/utilities';
import { useNotificationsStore } from './store/notifications';
import { markNotificationAsNotified } from '@services/projectPdf';
import { keyBy, orderBy, values } from 'lodash';
import { JOB_INSTANCE_STATUS } from '@constants';

const baseUrl = process.env.REACT_APP_API_FORM_WEBSOCKET;
const proxy = string.stripPath(baseUrl);
const namespace = new URL('/notifications', baseUrl)
export const socket = io(namespace.href, {
  path: proxy + '/ais',
  transports: ['websocket'],
  autoConnect: false,
  upgrade: false,
});

export const NotificationContext = createContext({});

export const NotificationProvider = ({ children }) => {
  const { accounts } = useMsal();
  const userId = accounts[0].localAccountId.toUpperCase();

  const notifications = useNotificationsStore((state) => state.notifications);
  const updateNotifications = useNotificationsStore((state) => state.updateNotifications);
  const notificationBellHasRedDot = useNotificationsStore(
    (state) => !!state.notifications?.filter((notification) => !notification.isRead && !notification.isDelete)?.length
  );

  const updateNotification = (index, key, value) => {
    const newNotifications = [...notifications];
    newNotifications[index][key] = value;

    const { JobStatusId, JobInstanceId } = newNotifications[index];

    // On notification read or delete, mark the notification as notified
    if (
      (key === 'isRead' || key === 'isDelete') &&
      value === true &&
      [JOB_INSTANCE_STATUS.COMPLETED, JOB_INSTANCE_STATUS.FAILED].includes(JobStatusId)
    ) {
      markNotificationAsNotified(JobInstanceId);
    }

    // Remove only notifications whose X button is clicked and job status is completed or failed 
    const filteredNewNotifications = newNotifications.filter(
      (notif) => !(notif.isDelete && [JOB_INSTANCE_STATUS.COMPLETED, JOB_INSTANCE_STATUS.FAILED].includes(JobStatusId))
    );

    // Remove any deleted notifications from the array
    updateNotifications(filteredNewNotifications);
  };
 
  useEffect(() => {
    const handleNewNotification = (newNotification) => {
      // Convert array to object indexed by JobInstanceId
      let jobsById = keyBy(notifications, 'JobInstanceId');

      const newNotifDataFromCurrentNotifs =
        notifications.find((notif) => notif.JobInstanceId === newNotification.JobInstanceId) ?? {};

      const hasJobStatusChanged = newNotifDataFromCurrentNotifs?.JobStatusId !== newNotification.JobStatusId;

      // Add or overwrite the object with the same JobInstanceId
      jobsById[newNotification.JobInstanceId] = {
        ...newNotification,
        isRead: hasJobStatusChanged ? false : (newNotifDataFromCurrentNotifs?.isRead || false),
        isDelete: hasJobStatusChanged ? false : (newNotifDataFromCurrentNotifs?.isDelete || false),
        JobStatusId: newNotification.JobStatusId,
        updatedDate: new Date().toISOString()
      };

      // Convert back to array
      let jobs = values(jobsById);

      // Sort by updatedDate in descending order
      jobs = orderBy(jobs, ['updatedDate'], ['desc']);

      // Update state
      updateNotifications(jobs);
    };

    socket.connect();

    socket.on('connect', () => {
      socket.emit('subscribe_to_notification', { userId });
    });

    socket.on('notify_subscribers', handleNewNotification);

    return () => {
      socket.off('connect');
      socket.off('notify_subscribers', handleNewNotification);
    };
  }, [notifications, updateNotifications, userId]);
  
  // Start: Sync local storage with store
  useEffect(() => {
    function updateNotificationsStore (event) {
      const { key } = event;
      if (key === 'claNotifications') {
        useNotificationsStore.persist.rehydrate();
      }
    }

    window.addEventListener('storage', updateNotificationsStore);

    return () => {
      window.removeEventListener('storage', updateNotificationsStore);
    };
  }, []);
  // End: Sync local storage with store

  return (
    <NotificationContext.Provider
      value={{ socket, notifications, notificationBellHasRedDot, updateNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotificationContext must be used within NotificationProvider');
  }
  return context;
};