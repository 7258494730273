import { createContext, useCallback, useState } from 'react';
import { useUpdateEffect, usePageLeave, useUnmount } from 'react-use';
import { isEqual } from 'lodash';
import { copyObject } from '@ais/forms';
import { useForm } from "react-hook-form"
import { Form } from "@ais/palette";   

export const VFCanvasContext = createContext({});

export const VFCanvasProvider = ({ schema, isInstance, answerList, onSaveAnswer, children }) => { 
  const initialValues = useCallback(() => {
    if (isInstance) {
      let answerValues = {}
      for (const [key, value] of Object.entries(answerList)) {
        answerValues[key.toLowerCase()] = value 
      };
      return copyObject(answerValues);
    } else {
      const formValues = {}
      schema?.properties?.forEach((property) => {
        property.fields.forEach((field) => {
          field.forEach((innerField) => {
            formValues[innerField.id] =
              innerField.defaultValue || innerField.value;
          });
        });
      });
      return copyObject(formValues);
    }
  }, [answerList, schema])

  const formCanvas = useForm({ values: initialValues() }); 

  const getIdsWithUpdatedValues = (currentValues, updatedValues) => {
    return currentValues ? Object.keys(updatedValues).filter(
      (key) =>
        !currentValues.hasOwnProperty(key)
        || (currentValues.hasOwnProperty(key) && !isEqual(currentValues[key], updatedValues[key]))
    ) : Object.keys(updatedValues)
  }

  const onSubmit = (values, currentValues, fromPageLeave) => {
    if (!isInstance || (!fromPageLeave)) {
      return;
    }

    const idListWithNewValues = getIdsWithUpdatedValues(currentValues, values);

    if (!idListWithNewValues || idListWithNewValues.length === 0) {
      return;
    }
    idListWithNewValues.forEach((answerId) => {
      const updatedAnswer = values[answerId]
      if (updatedAnswer === null || updatedAnswer === undefined) {
        return
      }
      onSaveAnswer(answerId, values[answerId])
    }); 
  };

  usePageLeave(() => { formCanvas.handleSubmit((values) => onSubmit(values, formCanvas.formState.defaultValues, true))() });
  useUnmount(() => formCanvas.handleSubmit((values) => onSubmit(values, formCanvas.formState.defaultValues, true))())  

  return (
    <VFCanvasContext.Provider
      value={{
        actions: {
          handleSubmit: formCanvas.handleSubmit((values) => onSubmit(values, formCanvas.formState.defaultValues)) 
        },
      }}
    >
      <Form form={formCanvas}>
        {children}
      </Form>
    </VFCanvasContext.Provider>
  )
};