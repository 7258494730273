import { useEffect, useState } from "react"
import { useDeepCompareEffect } from 'react-use';
import { useADDGeneralLedger, useGraphSelectionsMutation } from "@services/customForm/trendingByMonth"
import { Box, Button, IconButton, SwipeableDrawer, Paper } from '@mui/material';
import Typography from "@mui/material/Typography";
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from "@mui/system"
import { useSelectionState, useLoading } from "@hooks";
import { COMMON, GRAPHS_DRAWER } from "@constants"
import { GraphsDrawerTableItem } from "./GraphDrawerTableItem";
import { useFormContext } from "react-hook-form"
import { useGraphContext } from '@contexts/PlanningAnalytics/GraphContext';
import { useGetProjectTrialBalanceData } from '@services/trialbalance';
import { useParams } from 'react-router-dom';
import useProjectContext from "@contexts/ProjectContext";
import { useUpsertGraphMutation } from "@services/planningAnalytics";

const StyledFlexRow = styled("div")`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

const StyledTableHeaderCell = styled(TableCell)`
    font-family: 'museo-sans';
    font-style: normal;
    font-weight: 1000;
    font-size: 11px;
    line-height: 13px;
    color: #2E334E;
    background-color: #F7F7F7;
`

const transformDataStructure = (IdKey, IdName, propData, item) => {
    let data = propData
    if (!data) {
        data = []
    }
    if (item[IdKey] === null) {
        return data
    }
    const transformedData = {
        "Id": item.GLAccountNumber + "-" + item[IdKey],
        [`${IdKey}Id`]: item[IdKey],
        "Name": item[IdName],
        TrialBalanceId: item.TrialBalanceId
    }
    if (data.length < 1) {
        return [transformedData]
    }
    const index = data.findIndex(singleData => singleData[`${IdKey}Id`] === item[IdKey])
    if (index < 0) data.push(transformedData)
    return data
}

const initializeExpectedFormat = (
    id,
    typeId,
    name,
    trialBalanceId,
    isDefault = false
) => {
    return {
        Id: id,
        // TODO: Might need to update after implementing null handling for Planning Analytics in another US
        TypeId: typeId ?? 'placeholder',
        Name: name ?? 'placeholder',
        Categories: [],
        Classifications: [],
        TrialBalanceId: trialBalanceId,
        default: isDefault,
    }
}

const includedIn = (selectedGraphs, selectedId, idToCompare) => {
    if (!selectedGraphs) return false
    if (selectedGraphs.length < 1) return false
    return selectedGraphs.findIndex((selected) => selected[selectedId] === idToCompare) > -1
}

const GraphsDrawer = ({
    visible,
    onClose,
    onOpen,
    projectFormId,
    schemaSelectedGraphs,
    questionId,
    schema,
    isIdle
}) => {
    const { DRAWER_TITLE, DRAWER_DESCRIPTION, TABLE_HEADERS, DEFAULT_GRAPHS, DEFAULT_INCOME } = GRAPHS_DRAWER.EN
    const [incomeGraph, setIncomeGraph] = useState(initializeExpectedFormat("-1", "D. Income", "D. Income", DEFAULT_INCOME, true))
    const [items, setItems] = useState([incomeGraph])
    const [sectionActive, setSectionActive] = useState(false)
    const [clearingSelections, setClearingSelections] = useState(false)
    const [selectedTrialBalances, setSelectedTrialBalances] = useState([])
    const [correlationDetailIds, setCorrelationIds] = useState([])
    const [selectedGraphs, setSelectedGraphs] = useState([])
    const [selections, deletedSelections, { selectOne, selectAll, isSelected }] = useSelectionState("Id", selectedGraphs)
    const { projectId } = useParams();
    const [projectDetails, setProjectDetails] = useState({});

    const { getValues } = useFormContext();
    const { selectedGraphs: allSelectedGraphs, setSelectedGraphs: selectedGraph, setRefetchComments, trialBalances, actions: { handleGraphHistorical } } = useGraphContext()
    const { project } = useProjectContext();
    const {
        isFetching: isTrialBalanceBeingFetched,
        isLoading: isTrialBalanceLoading,
    } = useGetProjectTrialBalanceData(
        projectDetails?.AppDataInstanceId,
        projectDetails?.FiscalYear,
        projectId
    );

    const {
        data,
        isLoading
    } = useADDGeneralLedger(correlationDetailIds)
    const { OPTIONS, BUTTON } = COMMON.EN
    const mutation = useUpsertGraphMutation(projectId, projectFormId)
    const setLoading = useLoading()

    useDeepCompareEffect(() => {
        if (!project || Object.keys(project).length < 1) return;
        setProjectDetails(project);
    }, [project])

    useEffect(() => {

        if (!visible || selectedGraphs.length < 1)
            return;

        selectAll(selectedGraphs);
    }, [visible])

    useEffect(() => {
        if (isIdle) selectAll(selectedGraphs);
    }, [isIdle])

    useEffect(() => {
        if (!getValues()) return
        if (!schema) return
        const myFormValues = {}
        schema?.properties?.forEach((property) => {
            property.fields.forEach((field) => {
                field.forEach((innerField) => {
                    const formValue = getValues(innerField.id)
                    if (formValue) {
                        myFormValues[innerField.id] = formValue
                    }
                })
            })
        })
        let trialBalanceField = null
        for (const [key, value] of Object.entries(myFormValues)) {
            if (Array.isArray(value)) {
                trialBalanceField = value
                break
            }
        }
        if (trialBalanceField && trialBalanceField.length > 0) {
            setSelectedTrialBalances([...trialBalanceField.map((trialBalance) => trialBalance.value)])
            setCorrelationIds([...trialBalanceField.map((trialBalance) => trialBalance.correlationDetailId)])
        }
        if (trialBalanceField && trialBalanceField.length < 1) {
            setItems([{
                ...incomeGraph,
                Id: items.length > 0 ? items.find(item => item.TypeId === DEFAULT_GRAPHS.INCOME).Id : '-1'
            }])
            setCorrelationIds([])
        }
        const trendingByMonthField = myFormValues[questionId]
        if (trendingByMonthField && trendingByMonthField.toLowerCase() === OPTIONS.NO.toLowerCase()) {
            setSectionActive(true)
        }
        else {
            setSectionActive(false)
        }
    }, [onOpen, schema])

    const transformResponse = () => {
        // Transforms API response to our expected format for convenience
        let response = data;
        if (!response) {
            response = []
        }
        let types = Object.create({})
        let foundIncomeGraph = false
        let prevSelectedGraphs = []
        response.forEach((item) => {
            const typeKey = item.Type?.split('.')[1]?.trim() || '';
            let mapItem = types[typeKey];
            if (!mapItem) {
                mapItem = initializeExpectedFormat(item.GLAccountNumber, item.Type, item.Type, item.TrialBalanceId)
            }
            if (!foundIncomeGraph && mapItem["Name"].toLowerCase() === DEFAULT_GRAPHS.INCOME.toLowerCase()) {
                foundIncomeGraph = true
            }
            if (includedIn(schemaSelectedGraphs, "generalLedgerId", mapItem.Id) && !includedIn(prevSelectedGraphs, "Id", mapItem.Id)) {
                prevSelectedGraphs.push(mapItem)
            }
            let classifications = mapItem["Classifications"]
            const transformedClassifications = transformDataStructure("Classification", "Classification", classifications, item)
            transformedClassifications.forEach((transformedClassification) => {
                if (includedIn(schemaSelectedGraphs, "generalLedgerId", transformedClassification.Id) && !includedIn(prevSelectedGraphs, "Id", transformedClassification.Id)) {
                    prevSelectedGraphs.push(transformedClassification);
                }
            })
            mapItem["Classifications"] = transformedClassifications
            let categories = mapItem["Categories"]
            const transformedCategories = transformDataStructure("Category", "Category", categories, item)
            transformedCategories.forEach((transformedCategory) => {
                if (includedIn(schemaSelectedGraphs, "generalLedgerId", transformedCategory.Id) && !includedIn(prevSelectedGraphs, "Id", transformedCategory.Id)) {
                    prevSelectedGraphs.push(transformedCategory);
                }
            })
            mapItem["Categories"] = transformedCategories
            types[typeKey] = mapItem
        })
        if (!foundIncomeGraph) {
            types["incomeId"] = incomeGraph
            if (includedIn(schemaSelectedGraphs, "generalLedgerId", incomeGraph.Id) && !includedIn(prevSelectedGraphs, "Id", incomeGraph.Id)) {
                prevSelectedGraphs.push(incomeGraph);
            }
        }

        const newItems = Object.values(types);
        const Id = newItems.find(item => item.TypeId === DEFAULT_GRAPHS.INCOME).Id;
        const newGraph = { ...incomeGraph, Id };
        setItems(newItems.filter(item => item.Name !== 'placeholder' || item.Categories.length > 0 || item.Classifications.length > 0));
        setSelectedGraphs(prevSelectedGraphs);

        if (!selections.some((currentSelection) => currentSelection.Id === Id)) {
            if (selections.find((currentSelection) => currentSelection.Id === '-1')) {
                selectOne({ ...incomeGraph, Id: "-1" });
                return;
            }
            selectOne(newGraph);
        }
    }

    const selectIncomeGraph = () => {
        if (!visible)
            return;

        selectOne(incomeGraph);
    }

    const onClearSelections = () => {
        setClearingSelections(true)
        selectAll([])
    }

    const onCloseDrawer = () => {
        onClearSelections()
        if (onClose) onClose()
    }

    const onSaveSelections = async (isFromDrawer = true) => {
        const selectionsToBePosted = [];
        const incomeDefaultValue = items.find(type => type.TypeId === 'D. Income')

        selections.forEach((selection) => {
            if (selection?.TrialBalanceId === DEFAULT_INCOME || selectedTrialBalances.includes(selection?.TrialBalanceId)) {
                const groupingDetails = {
                    groupingId: selection.TypeId,
                    grouping: "Type"
                }

                if (selection.ClassificationId !== undefined) {
                    groupingDetails.groupingId = selection.ClassificationId;
                    groupingDetails.grouping = "Classification";
                }
                else if (selection.CategoryId !== undefined) {
                    groupingDetails.groupingId = selection.CategoryId;
                    groupingDetails.grouping = "Category";
                }

                const isDIncomeTypeIncluded = selectionsToBePosted.some((selectionToBePosted) => {
                    return (
                        selectionToBePosted.trialBalanceId === incomeDefaultValue?.TrialBalanceId &&
                        selectionToBePosted.groupingId === selection.TypeId
                    );
                });

                if (!isDIncomeTypeIncluded) {
                    selectionsToBePosted.push({
                        ...groupingDetails,
                        generalLedgerId: selection.Id,
                        name: selection.Name,
                        trialBalanceId: selection?.TrialBalanceId === DEFAULT_INCOME ? incomeDefaultValue?.TrialBalanceId : selectedTrialBalances,
                    });
                }
            }
        });

        setLoading(true);
        const { Data: data, ProjectPlanningAnalyticGraphHistorical } = await mutation.mutateAsync(selectionsToBePosted.map(data => {
            const selectedGraph = allSelectedGraphs.find(graph => graph.groupingId === data.groupingId
                && graph.generalLedgerId === data.generalLedgerId
                && graph.grouping === data.grouping
                && graph.name === data.name
            )
            return ({
                ProjectPlanningAnalyticGraphId: selectedGraph?.projectPlanningAnalyticGraphId ?? null,
                GroupingId: data?.groupingId,
                GroupingName: data?.grouping,
                GeneralLedgerId: data?.generalLedgerId,
                GeneralLedgerName: data?.name,
                TrialBalanceId: data?.trialBalanceId,
            })
        }))
        handleGraphHistorical(ProjectPlanningAnalyticGraphHistorical)
        selectedGraph(data.map(data => ({
            projectPlanningAnalyticGraphId: data?.ProjectPlanningAnalyticGraphId,
            groupingId: data?.GroupingId,
            grouping: data?.GroupingName,
            generalLedgerId: data?.GeneralLedgerId,
            name: data?.GeneralLedgerName,
            trialBalanceId: data?.TrialBalanceId?.includes(DEFAULT_INCOME) ? DEFAULT_INCOME : data?.TrialBalanceId,
        })))
        setRefetchComments(true);
        if (isFromDrawer) {
            onCloseDrawer();
        }
        setLoading(false)
    }

    useEffect(() => {
        if (isLoading) return
        transformResponse()
    }, [isLoading, visible])

    useEffect(() => {
        if (!items) return
        if (items.length > 0) {
            setIncomeGraph(prev => ({ ...prev, Id: items.find(item => item.TypeId === DEFAULT_GRAPHS.INCOME).Id }))
        }
    }, [items])

    useEffect(() => {
        if (!visible) {
            if (!sectionActive) selectAll([])
            return
        }
        if (!items) {
            selectIncomeGraph()
            return
        }
        if (items.length < 1) {
            selectIncomeGraph()
            return
        }
        if (selections.length > 0) return
        if (sectionActive) selectIncomeGraph()
    }, [visible])

    useEffect(() => {
        if (!selections) return
        if (selections.length < 1 && clearingSelections) {
            selectIncomeGraph()
            setClearingSelections(false)
        }
    }, [selections, clearingSelections])

    useEffect(async () => {
        if (trialBalances === undefined || trialBalances === null) return;
        if (isTrialBalanceBeingFetched || isTrialBalanceLoading) return;


        let trialBalanceId = DEFAULT_INCOME;

        if (Array.isArray(trialBalances) && trialBalances.length === 1) {
            trialBalanceId = trialBalances[0].value;
        } else if (Array.isArray(trialBalances) && trialBalances.length > 1) {
            trialBalanceId = trialBalances.map(({ value }) => value);
        }

        setLoading(true);

        const initialDefaultGraph = [{
            groupingId: 'D. Income',
            grouping: 'Type',
            generalLedgerId: '-1',
            name: 'D. Income',
            trialBalanceId,
        }];
        if (selectedGraphs.length > 0) {
            setSelectedGraphs([]);
            onClearSelections();
        }

        const { Data: data, ProjectPlanningAnalyticGraphHistorical} = await mutation.mutateAsync(initialDefaultGraph.map((data) => ({
            ProjectPlanningAnalyticGraphId: null,
            GroupingId: data?.groupingId,
            GroupingName: data?.grouping,
            GeneralLedgerId: data?.generalLedgerId,
            GeneralLedgerName: data?.name,
            TrialBalanceId: data?.trialBalanceId,
        })))
        handleGraphHistorical(ProjectPlanningAnalyticGraphHistorical)
        selectedGraph(data.map(data => ({
            projectPlanningAnalyticGraphId: data?.ProjectPlanningAnalyticGraphId,
            groupingId: data?.GroupingId,
            grouping: data?.GroupingName,
            generalLedgerId: data?.GeneralLedgerId,
            name: data?.GeneralLedgerName,
            trialBalanceId: data?.TrialBalanceId?.includes(DEFAULT_INCOME) ? DEFAULT_INCOME : data?.TrialBalanceId,
        })));


        setRefetchComments(true);
        setLoading(false)

    }, [trialBalances])

    return (
        <Box sx={{ margin: "14px" }}>
            <SwipeableDrawer
                sx={{
                    width: '50%',
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: '50%', backgroundColor: '#E4E0E0' },
                }}
                anchor="right"
                open={visible}
                onClose={onCloseDrawer}
                onOpen={onOpen}
            >
                <Box sx={{ margin: "14px" }}>
                    <StyledFlexRow>
                        <IconButton
                            variant="close-drawer-button"
                            sx={{ right: 25, }}
                            onClick={onCloseDrawer}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="title" sx={{ marginTop: "46px", marginLeft: "17px", fontSize: "17px" }}>
                            {DRAWER_DESCRIPTION}
                        </Typography>
                    </StyledFlexRow>
                    <Paper sx={{ margin: "14px", paddingTop: "13px", paddingBottom: "13px" }} elavation={0}>
                        <StyledFlexRow sx={{ margin: "14px" }}>
                            <Typography variant="title">{DRAWER_TITLE}</Typography>

                            <StyledFlexRow>
                                <Button variant="outlined" onClick={onClearSelections}>
                                    {BUTTON.CLEAR}
                                </Button>

                                <Button variant="contained" sx={{ marginLeft: "20px" }} onClick={onSaveSelections}>
                                    {BUTTON.SAVE}
                                </Button>
                            </StyledFlexRow>
                        </StyledFlexRow>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <StyledTableHeaderCell>{TABLE_HEADERS.TYPE}</StyledTableHeaderCell>
                                    <StyledTableHeaderCell>{TABLE_HEADERS.CLASSIFICATION}</StyledTableHeaderCell>
                                    <StyledTableHeaderCell>{TABLE_HEADERS.CATEGORY}</StyledTableHeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ paddingLeft: "13px", paddingRight: "13px" }}>
                                {items.map((item) => (
                                    <GraphsDrawerTableItem
                                        key={item.Id}
                                        enableMinimumSelection={sectionActive}
                                        selections={selections}
                                        isSelected={isSelected}
                                        selectOne={selectOne}
                                        item={item}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </Paper>
                </Box>
            </SwipeableDrawer>
        </Box>
    )
}

export default GraphsDrawer
