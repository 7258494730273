export const CUSTOM_FORMS = {
    PLANNING_ANALYTICS: {
        formCategoryName: "Risk Assessment Procedures",
        formName: "0420.00 Planning Analytics",
        formDescription: "Planning Analytics",
        formStatusId: 1,
        requiredSignOffLevel: 2,
        isLimited: true,
        formTypeId: 3
    },
    RISK_ASSESSMENT_SUMMARY: {
        formCategoryName: "Risk Assessment Procedures",
        formName: "0499.00 Risk Assessment Summary",
        formDescription: "Risk Assessment Summary",
        formStatusId: 1,
        requiredSignOffLevel: 2,
        isLimited: true,
        formTypeId: 4
    },
    KPI: {
        formCategoryName: "Pre-Engagement Procedures",
        formName: "0121.00 KPI Inputs Form",
        formDescription: "KPI Inputs Form",
        formStatusId: 1,
        requiredSignOffLevel: 2,
        isLimited: true,
        formTypeId: 5
    },
    EVALUATION_AND_COMMUNICATION: {
        formCategoryName: "Engagement Conclusions",
        formName: "0220.00 Evaluation and Communication of Deficiencies in Internal Control",
        formDescription: "Evaluation and Communication of Deficiencies in Internal Control",
        formStatusId: 1,
        requiredSignOffLevel: 2,
        isLimited: true,
        formTypeId: 6
    },
    UNDERSTANDIONG_OF_ENTITY: { 
        formCategoryName: "Evaluating Internal Control",
        formName: "0500.15 Understanding the Entity's Information System and Communication and Control Activities",
        formDescription: "Understanding of Internal Control and Walkthroughs of Key Controls",
        formStatusId: 1,
        requiredSignOffLevel: 2,
        isLimited: true,
        formTypeId: 7
    }
}