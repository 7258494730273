import React, {
    useState
} from 'react';

import {
    TableRow,
    Collapse,
    IconButton
} from '@mui/material';

import {CollapseIconComponent, ExpandIconComponent} from "@ais/assets";
import { DEFICIENCY_FORM_INSTANCE } from '@constants/forms';

import CommunicationOfDeficienciesComment from './CommunicationOfDeficienciesComment';
import CommunicationOfDeficienciesSubRow from './CommunicationOfDeficienciesSubRow';
import CommunicationOfDeficienciesCell from './CommunicationOfDeficienciesCell';
import CommunicationOfOtherDeficienciesSubRow from './CommunicationOfOtherDeficienciesSubRow';
import { useUpdateMyPresence } from "@components/Concurrency/provider/RoomProvider";
import { useOthers } from "@components/Concurrency/store/users";
import { useProjectFormInstanceProvider } from '@providers';
import { useFinalizedProject } from '@hooks/useProject';

const CommunicationOfDeficienciesRowCollapsible = (props) => {
    const { ID } = DEFICIENCY_FORM_INSTANCE.EN;
    const { row, columns, projectId, handleSave, otherDeficiency } = props;
    const [isExpanded, setIsExpanded] = useState(false);
    const [commentAreaVisible, setComentAreaVisible] = useState(false);
    const others = useOthers();
    const { userId } = useProjectFormInstanceProvider();
    const isProjectFinalized = useFinalizedProject(projectId);
    const isDisabled = others.find((user) => user.presence.focusedId === ID && user.info?.userId?.toLowerCase() !== userId?.toLowerCase()) || isProjectFinalized;
    const updateMyPresence = useUpdateMyPresence()
    return (
        <>
            <TableRow
                id={row?.id}
                className={'MuiDataGrid-columnHeadersInner'}
                sx={{
                    display: 'flex',
                    p: '0 10px',
                    borderBottom: isExpanded ? '0px' : '1px solid rgba(224, 224, 224, 1)'
                }}
            >
                <IconButton
                    sx={{ p: '0', alignItems: 'flex-start', paddingTop: '20px', marginLeft: '10px' }}
                    onClick={() => { setIsExpanded(prevIsExpanded => !prevIsExpanded) }}>
                    {isExpanded ? <ExpandIconComponent /> : <CollapseIconComponent />}
                </IconButton>

                <CommunicationOfDeficienciesCell
                    row={row}
                    columns={columns}
                    projectId={projectId}
                    setIsExpanded={setIsExpanded}
                    setComentAreaVisible={setComentAreaVisible}
                    commentAreaVisible={commentAreaVisible}
                    otherDeficiency={otherDeficiency}
                />
            </TableRow>
            <Collapse in={isExpanded} collapsedSize={0}>
                <TableRow
                    id={row?.id}
                    className={'MuiDataGrid-columnHeadersInner'}
                    sx={{
                        display: 'block',
                        p: '0 10px',
                        borderBottom: commentAreaVisible ? '0px' : '1px solid rgba(224, 224, 224, 1)'
                    }}
                >
                    {
                        !otherDeficiency ? (
                            <CommunicationOfDeficienciesSubRow
                                row={row}
                                onAutoSave={handleSave}
                                disabled={isDisabled}
                            />
                        ) : (
                            <CommunicationOfOtherDeficienciesSubRow
                                row={row}
                                onAutoSave={handleSave}
                                disabled={isDisabled}
                            />
                        )
                    }
                </TableRow>
                {commentAreaVisible && <TableRow
                    id={row?.id}
                    className={'MuiDataGrid-columnHeadersInner'}
                    sx={{
                        display: 'block',
                        p: '0px 10px 20px 0px',
                        borderBottom: '1px solid rgba(224, 224, 224, 1)'
                    }}
                >
                    <CommunicationOfDeficienciesComment
                        row={row}
                        handleCommentSave={handleSave}
                        disabled={isDisabled}
                        updateMyPresence={updateMyPresence}
                    />
                </TableRow>}
            </Collapse>
        </>
    );
}

export default CommunicationOfDeficienciesRowCollapsible;
