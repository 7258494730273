import { useParams } from "react-router-dom";
import { useProject, useProjectForDiagnostic } from "@services/project";
import { PROJECT_STATE } from "@constants/common";

export const useFinalizedProject = (projectId) => {
    const { data: project } = useProject(projectId)
    return project?.ProjectStateId === PROJECT_STATE.FINALIZED
}

export const useActiveProject = (projectId) => {
    const { data: project } = useProject(projectId)
    return project?.ProjectStateId === PROJECT_STATE.ACTIVE
}

export const useDiagnosticFinalizedProject = (projectId) => {
    const { data: project } = useProjectForDiagnostic(projectId)
    return project?.ProjectStateId === PROJECT_STATE.FINALIZED
}

export const useFinalizedProjectNoId = () => {
    const { projectId } = useParams();
    const { data: project } = useProject(projectId);
    return project?.ProjectStateId === PROJECT_STATE.FINALIZED
}

export const useDraftProject = (projectId) => {
    const { data: project } = useProject(projectId)
    return project?.ProjectStateId === PROJECT_STATE.DRAFT
}
