import { useEffect } from 'react';
import { useFormContext } from "react-hook-form";

import { CLASnackbarWithContent } from "@ais/components";
import {
    INTERNAL_CONTROLS_FORM_TYPE_ID,
    PLANNING_ANALYTICS_FORM_TYPE_ID,
    USER_FORM_TYPE_ID
  } from '@ais/constants';
import { transformAnswerFromUpdate } from '@ais/utilities';
import {
    AUDIT_SCOPING_FORM_TYPE_ID,
    RISK_ASSESSMENT_FORM_TYPE_ID,
    KPI_INPUTS_FORM_TYPE_ID,
    DEFICIENCY_EVALUATION_FORM_TYPE_ID
} from '@constants/forms';
import { useGraphContext } from '@contexts/PlanningAnalytics/GraphContext';
import { useOthers, useOtherUsersStore } from '@components/Concurrency/store/users';
import { useEventListener, useOthersListener, useUpdateMyPresence } from '@components/Concurrency/provider/RoomProvider';
import { useRoomIdle } from '@ais/hooks';
import { useProjectFormInstanceProviderV2 } from '@providers';
import { useProjectScope } from '@views/ProjectScopingForm';
import { useRiskAssessment } from '@views/RiskAssesmentSummary';
import logger from '@utilities/logService'
import useICF from '@views/InternalControl/hooks/useICF';
import { useDeficiency } from '@views/DeficiencySummaryForm/hooks/useDeficiency';
import { usePlanningAnalytics } from '@views/PlanningAnalytics/hooks/usePlanningAnalytics';
import { GRAPHS_DRAWER } from "@constants/index";

export default function ConcurrentUsersBanner({ userId }) {
    const append = useOtherUsersStore((state) => state.append)
    const remove = useOtherUsersStore((state) => state.remove)
    const updateUserPresence = useOtherUsersStore((state) => state.updateUserPresence)
    const {
        updateAuditAreaData,
        setConcurrencyEventReceived
    } = useProjectScope();
    const { setSelectedPriorPeriod, setSelectedGraphs } = useGraphContext()

    const {
        updateNonCustomFieldAnswer,
        updateFinancialStatementLevelPersuasiveRisk,
        updateAssertionComment,
        updateAuditAreaRisk,
        updateOtherRelevantAssertion,
        setConcurrencyEventReceived: setConcurrencyEventReceivedRiskAssessment,
    } = useRiskAssessment();

    const {
        setConcurrencyEventReceived: IcfConcurrencyEventReceived,
        setDataOnBroadcastEvent
    } = useICF();

    const {
        setConcurrencyEventReceived: DsfConcurrencyEventReceived,
        setDeficiencies,
        setDeficiencyCombinations
    } = useDeficiency();
    const {
        updateNonCustomFieldAnswer: PlanningUpdateNonCustomFieldAnswer,
        setConcurrencyEventReceived: PlanningConcurrencyEventReceived,
        setComment
    } = usePlanningAnalytics();

    const isIdle = useRoomIdle();
    const updateMyPresence = useUpdateMyPresence();
    const { getValues, setValue } = useFormContext();
    const { projectForm: { formTypeId }, answerList, focusedId, fieldRef, actions: { onFocus, onUpdateEvent } } = useProjectFormInstanceProviderV2();

    useOthersListener(({ type, sessionId, data }) => {
        switch (type) {
            case "enter":
                append({ sessionId, ...data })
                return
            case "leave":
                remove(sessionId)
                return
            case "presence":
                updateUserPresence(sessionId, data)
            default:
                logger.warn(`Invalid event type: `, type)
        }
    });

    const updateAnswer = (id, answer) => {
        const updatedAnswer = transformAnswerFromUpdate(id, answer); 
        const updatedAnswerList = getValues(); 
        setValue(id, updatedAnswer);
        onUpdateEvent(() => {
            return {
                ...updatedAnswerList,
                [id]: updatedAnswer
            }
        });
    }

    const handleUpdateEvents = (data) => {
        const { answer, id } = data;

        switch (formTypeId) {
            case USER_FORM_TYPE_ID:
            case KPI_INPUTS_FORM_TYPE_ID:
                updateAnswer(id, answer.answerValue);
                return;
            case PLANNING_ANALYTICS_FORM_TYPE_ID:
                PlanningConcurrencyEventReceived(true);
                if (data.answer.userId !== userId) {
                    if ('selectedPeriods' in data.answer) {
                        const { selectedPeriods } = data.answer
                        setSelectedPriorPeriod(selectedPeriods?.Data.map(data => ({
                            projectPlanningAnalyticPeriodId: data?.ProjectPlanningAnalyticPeriodId,
                            value: data?.PeriodValue,
                            label: data?.PeriodLabel,
                            color: data?.Color,
                            code: data?.Code
                        })))
                    } else if ('selectedGraphs' in data.answer) {
                        const { selectedGraphs } = data.answer;
                        
                        setSelectedGraphs(selectedGraphs?.Data.map(data => ({
                            projectPlanningAnalyticGraphId: data?.ProjectPlanningAnalyticGraphId,
                            groupingId: data?.GroupingId,
                            grouping: data?.GroupingName,
                            generalLedgerId: data?.GeneralLedgerId,
                            name: data?.GeneralLedgerName,
                            trialBalanceId: data?.TrialBalanceId?.includes(GRAPHS_DRAWER.EN.DEFAULT_INCOME) ? GRAPHS_DRAWER.EN.DEFAULT_INCOME : data?.TrialBalanceId
                        })))
                    } else if ('comment' in data.answer) {
                        setComment(data.answer.comment)
                    } else {
                        updateAnswer(data.id, data.answer.answerValue);
                    }
                }
                return;
            case AUDIT_SCOPING_FORM_TYPE_ID:
                setConcurrencyEventReceived(true);
                if (answer.isNonCustomField) {
                    updateAnswer(id, answer.answerValue);
                } else {
                    updateAuditAreaData(data.id, data.answer);
                }
                return;
            case RISK_ASSESSMENT_FORM_TYPE_ID: 
                setConcurrencyEventReceivedRiskAssessment(true);
                if ('RiskAssessmentComment' in data.answer) {
                    updateAssertionComment(data.answer);
                }
                else if ('risksummary' in data.answer) {
                    const identifiedRisk = data.answer.reqBody.hasOwnProperty('ReferenceForWalkThrough')
                    if (identifiedRisk) {
                        updateAuditAreaRisk(data.answer);
                    } else {
                        updateFinancialStatementLevelPersuasiveRisk(data.answer.risksummary)
                    }
                }
                else if ('otherRelevantAssertion' in data.answer) {
                    updateOtherRelevantAssertion(data.id, data.answer)
                } 
                else if ('financialStatementProjectAuditAreaRisk' in data.answer) {
                    updateAuditAreaRisk(data.answer);
                }
                else {
                    updateAnswer(data.id, data.answer.answerValue);
                }
                return;
            case DEFICIENCY_EVALUATION_FORM_TYPE_ID: 
                DsfConcurrencyEventReceived(true);
                if ('deficiency' in answer) {
                    setDeficiencies(answer.deficiency)
                } else if ('deficiencyCombination' in answer) {
                    setDeficiencyCombinations(answer.deficiencyCombination)
                }
                return;
            case INTERNAL_CONTROLS_FORM_TYPE_ID:
                IcfConcurrencyEventReceived(true);
                setDataOnBroadcastEvent(data)
                return;
            default:
                return
        }
    }

    useEventListener(({ type, data }) => {
        switch (type) {
            case "updates": 
                if (data.answer?.userId?.toUpperCase() !== userId?.toUpperCase())
                    handleUpdateEvents(data);
                return
            default:
                logger.warn(`Invalid event type: `, type)
        }
    })

    useEffect(() => {
        if (isIdle) {
            if (focusedId) {
                let _focusedId = focusedId
                const isQuestionGroup = _focusedId.includes('.')
                if (isQuestionGroup) _focusedId = _focusedId.split('.')[0]
                setValue(_focusedId, answerList[_focusedId])
                onUpdateEvent(prev => {
                    return { ...prev, [_focusedId]: answerList[_focusedId] }
                });
                fieldRef.current?.blur()
                fieldRef.current = null
                onFocus(null, null)
            }
            updateMyPresence({ focusedId: null })
        }
    }, [isIdle])

    const getDisplayUsers = (displayUsers, user) => {
        if (user.info.userId?.toLowerCase() !== userId?.toLowerCase() && !displayUsers.some(function (displayUser) { return displayUser.info.name === user.info.name; })) {  
            displayUsers.push(user);
        } 
        return displayUsers;
    }

    const users = useOthers()
    return (
        <CLASnackbarWithContent
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            snackBarPostion={'top-center'}
            open={users?.reduce(getDisplayUsers, []).length > 0}
            message={
                <>
                    {users?.reduce(getDisplayUsers, []).map((item, index) => (
                        <p key={index} style={{ textAlign: 'center', margin: 0 }}>
                            {`${item.info.name} is currently editing this form`}
                        </p>
                    ))}
                </>
            }
        />
    )
}

