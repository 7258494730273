import React from 'react';
import IconButton from '@mui/material/IconButton';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { useNotificationContext } from '../../providers/notifications/NotificationProvider';

const StyledBadge = styled(Badge)(() => ({
    '& .MuiBadge-badge': {
        border: `1px solid #fff`,
        borderRadius: '8px',
        height: '16px',
        width: '16px'
    },
  }));

export default function CLANotificationIconButton() {
  const {notificationBellHasRedDot} = useNotificationContext();
  return (
    <IconButton aria-label="notification">
      <StyledBadge 
        color="error"
        variant="dot" 
        anchorOrigin={
            { 
              vertical: 'bottom',
              horizontal: 'right'
            }
        } 
        overlap='circular'
        invisible={!notificationBellHasRedDot}
        >
          <NotificationsIcon sx={{ fontSize: 30, color: '#2e334e' }} />
      </StyledBadge>
    </IconButton>
  );
}