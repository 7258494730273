export const GREETINGS = "Hello, "
export const CLIENT_ID = "Client ID "
export const PROJECT_ID = "Project ID "
export const PROJECT_NUMBER = "Project Number "
export const DASHBOARD_SUB_TITLE = 'Assurance Integrated System (AIS)'

export const REDIRECT_LOGOUT = 'https://mycla.claconnect.com/'
export const UNAUTHPAGE_CONTENT_LINE_1 = 'You do not currently have access to this project.'
export const UNAUTHPAGE_CONTENT_LINE_2 = 'Please contact the Engagement Leader associated with this project to request access.'
export const NO_PROJECT_FORM_LOCATED = 'The project or form cannot be located.'
export const SOMETHING_WENT_WRONG_LINE_1 = 'Something Went Wrong!'
export const SOMETHING_WENT_WRONG_LINE_2 = 'Contact the service desk if problem persists'