export const CLA_METHODOLOGY = {
    EN: {
        APPROVE: 'Approve',
        RELEASE: 'Release',
        ADMIN: 'Administrator',
        DRAFT: 'Draft',
        READY_FOR_RELEASE: 'Ready for Release',
        VIEW: 'View',
        GENERATE: 'Generate',
        VIEWEDIT: 'View/Edit',
        HEADER: 'Methodology Management',
        HEADER_TITLE: 'Methodology',
        FOOTER_BUTTON: 'ADD METHODOLOGY VERSION',
        METHODOLOGY_VERSION: 'Methodology Version',
        METHODOLOGY_LABEL: 'Methodology Label',
        STATUS: 'Status',
        INCLUDED_FORMS: 'Forms Library',
        INCLUDED_PROCEDURE: 'Procedures Library',
        VIEW: 'View',
        NO_VIEW: '(0) View',
        MINOR_RELEASE: 'Make a Minor Release',
        DESCRIPTION: 'Description',
        RELEASE_NOTES: 'Release Notes',
        CANCEL: 'Cancel',
        CREATE: 'Create',
        SAVE: 'Save',
        ARCHIVE: 'Archive',
        Archive_HEADER: 'Archive Methodology Version',
        Archive_MESSAGE: 'Are you sure you want to archive Methodology Version',
        DRAFT_METHODOLOGY_PANEL: 'draft-methodology-panel',
        METHODOLOGY_SUCCESS: 'Methodology Version created successfully.',
        CANCEL_TEXT: 'No, go back',
        TITLE: 'Warning',
        CONFIRM_TEXT: 'Yes, cancel',
        MESSAGE: 'Are you sure you want to cancel? Data will be deleted permanently.',
        PLACEHOLDER: 'Text',
        REQUIRED: 'Starting Version Id is required',
        REPORTS: {
            CHANGE_REPORT: 'Change Report',
            STARTINGVERSION: 'Starting Version',
            ENDINGVERSION: 'Ending Version',
            GENERATE: 'Generate',
            DESCRIPTION: 'Description',
            NO_DATA: 'No Data Loaded.',
            QUESTIONS: 'Questions',
            COLLAPSE_ALL: 'Collapse All',
            EXPAND_ALL: 'Expand All',
            CURRENT_QUESTION: 'Current Question',
            PREVIOUS_QUESTION: 'Previous Question',
            NA: '[N/A]'
        }
    },
    STATUS: {
        RELEASED: 3,
        ARCHIVED: 4
    }
}

export const DRAFT_PROJECTS = {
    EN: {
        TITLE: 'Draft Projects',
        BUTTON: 'ADD DRAFT PROJECT',
        ELLIPSIS: {
            OPEN: 'Open',
            EDIT: 'Edit',
            DELETE: 'Delete'
        },
        COLUMN: {
            PROJECT_NAME: 'Project Name',
            METHODOLOGY: 'Methodology',
            PRIMARY_INDUSTRY: 'Primary Industry',
            SECONDARY_INDUSTRY: 'Secondary Industry',
            REPORT_NAME: 'Report Name',
            UNITS: 'Units'
        },
    }
}