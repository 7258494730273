import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MenuItem } from '@mui/material';
import { ReactComponent as RiskAlertIcon } from "@assets/icon_risk_alert.svg";
import { ReactComponent as DeficiencyInfoIcon } from "@assets/form_deficiency_info_icon.svg";
import { CLAActionEllipsis } from '@ais/forms';
import { ProcedureHeader } from '../ProcedureHeader/ProcedureHeader';
import GraphsManager from '@views/PlanningAnalytics/components/Graphs/GraphsManager';

import styles from '@components/FormView/FormView.module.css';
import {
    ACTION_ELLIPSIS,
    ACTION_ELLIPSIS_TYPES,
    DEFICIENCY_FORM_INSTANCE,
    TRENDING_BY_MONTH
} from '@constants/forms';
import {
    DRAG_TYPES,
    FIELD_TYPES,
    INTERNAL_CONTROLS_FORM_TYPE_ID
} from '@ais/constants';
import { AUDIT_SECTIONS_TITLE } from '@constants/forms/auditscoping';
import { KPI_INPUTS_SECTION_TITLE } from '@constants/forms/kpiInputs';

import { useUpdateMyPresence } from "@components/Concurrency/provider/RoomProvider";
import { useOthers } from "@components/Concurrency/store/users";
import { useProjectRiskDeficienciesContext } from '@contexts/ProjectRiskDeficienciesContext';
import { useProjectFormInstanceProviderV2 } from '@providers';
import { useAccountAssertionLevelRiskState } from '@views/RiskAssesmentSummary/components/AccountAssertionLevelRisk/AccountAssertionLevelRiskContext';
import { useRiskAssessment } from '@views/RiskAssesmentSummary';
import { useProject } from '@services/project';
import { useGETServiceTypeConfigurationByMethodologyVersionId } from '@services/methodology';


const riskAssessmentAppliedMenuItems = [
    {
        label: ACTION_ELLIPSIS.ADD_QUESTION,
        type: ACTION_ELLIPSIS_TYPES.ADD_QUESTION,
    },
    {
        label: ACTION_ELLIPSIS.ADD_RISK,
        type: ACTION_ELLIPSIS_TYPES.ADD_RISK,
    },
    {
        label: ACTION_ELLIPSIS.ADD_RISK_FACTOR,
        type: ACTION_ELLIPSIS_TYPES.ADD_RISK_FACTOR,
    },
    {
        label: ACTION_ELLIPSIS.ADD_DEFICIENCY,
        type: ACTION_ELLIPSIS_TYPES.ADD_DEFICIENCY,
    },
    {
        label: ACTION_ELLIPSIS.EDIT_MOVE_QUESTION,
        type: ACTION_ELLIPSIS_TYPES.EDIT_MOVE_QUESTION,
    }
];

const customSectionMenuItems = [
    {
        label: ACTION_ELLIPSIS.ADD_RISK,
        type: ACTION_ELLIPSIS_TYPES.ADD_RISK,
    },
    {
        label: ACTION_ELLIPSIS.ADD_RISK_FACTOR,
        type: ACTION_ELLIPSIS_TYPES.ADD_RISK_FACTOR,
    },
    {
        label: ACTION_ELLIPSIS.ADD_DEFICIENCY,
        type: ACTION_ELLIPSIS_TYPES.ADD_DEFICIENCY,
    },
];

const riskAssessmentNotAppliedMenuItems = [
    {
        label: ACTION_ELLIPSIS.ADD_QUESTION,
        type: ACTION_ELLIPSIS_TYPES.ADD_QUESTION,
    },
    {
        label: ACTION_ELLIPSIS.EDIT_MOVE_QUESTION,
        type: ACTION_ELLIPSIS_TYPES.EDIT_MOVE_QUESTION,
    }
];

export const EllipsisHeader = ({ isAssertionLevelRiskSection, isProjectFinalized, formTypeId, sectionType, section, setModalType, }) => {
    const { id, type = '', title } = section;
    const { ID } = DEFICIENCY_FORM_INSTANCE.EN;
    const { projectId, projectFormId } = useParams()
    const { projectFormRisks, projectFormDeficiencies } = useProjectRiskDeficienciesContext();
    const navigate = useNavigate();
    const formIsInternalControl = formTypeId === INTERNAL_CONTROLS_FORM_TYPE_ID;
    const { state, expandAll, collapseAll, updateChildExpanded, isChildExpanded } = useAccountAssertionLevelRiskState();
    const { actions: { updateIsFinancialStatementExpanded, updateIsAssertionLevelRiskExpanded } } = useRiskAssessment();
    const { schema, userId } = useProjectFormInstanceProviderV2();
    const { allExpanded } = state;
    const isExpanded = isChildExpanded(section.id);
    const isFinancialStatementLevelRisk = sectionType === 'CUSTOM' && section?.fields?.flatMap(i => i).some(f => f.type === FIELD_TYPES.FINANCIAL_STATEMENT_LEVEL_RISK);
    const isTrendingByMonthSection = title === TRENDING_BY_MONTH.title;

    const others = useOthers();
    const updateMyPresence = useUpdateMyPresence();

    const {
        data: projectData
    } = useProject(projectId)
    const MethodologyVersionId = projectData?.MethodologyVersionId;
    const {
        data: methodologyVersionServiceConfiguration,
    } = useGETServiceTypeConfigurationByMethodologyVersionId(MethodologyVersionId);

    const isRiskAssessmentApplied = methodologyVersionServiceConfiguration?.IsRiskAssessmentApplied ?? false;

    const menu =
        sectionType === 'CUSTOM'
            ? customSectionMenuItems
            : isRiskAssessmentApplied
                ? riskAssessmentAppliedMenuItems
                : riskAssessmentNotAppliedMenuItems;

    const getShouldDisplayEllipsis = (
        section,
        isAssertionLevelRiskSection,
        formIsInternalControl,
        sectionType,
        isRiskAssessmentApplied
    ) => {
        const initialConditionsAreTrue =
            section?.showContextMenu &&
            !isAssertionLevelRiskSection &&
            !(formIsInternalControl && sectionType === DRAG_TYPES.SECTION_SEMICUSTOM);

        if (!initialConditionsAreTrue) return false;

        // Risk Assessment is checked in Service Type Page
        if (isRiskAssessmentApplied) return true;

        // If unchecked, check the custom sections
        if (sectionType === DRAG_TYPES.SECTION_CUSTOM) {
            // These custom sections should not display ellipsis when Risk Assessment is unchecked
            // Add more custom sections in this array in case of future user stories that require them
            return ![
                KPI_INPUTS_SECTION_TITLE.TRIAL_BALANCE_SELECTION,
                AUDIT_SECTIONS_TITLE.INITIAL_SCOPING_CONSIDERATIONS
            ].includes(section?.title);
        }

        return true;
    };

    const shouldDisplayEllipsis = useMemo(
        () =>
            getShouldDisplayEllipsis(
                section,
                isAssertionLevelRiskSection,
                formIsInternalControl,
                sectionType,
                isRiskAssessmentApplied
            ),
        [section, isAssertionLevelRiskSection, formIsInternalControl, sectionType, isRiskAssessmentApplied]
    );

    const isEllipseDisabled = others.find((user) => user.presence.focusedId === ID && user.info.userId.toLowerCase() !== userId.toLowerCase())

    useEffect(() => {
        updateChildExpanded(section.id, !section?.showCollapsed);
    }, [section?.showCollapsed, isFinancialStatementLevelRisk]);

    useEffect(() => {
        if (!!isFinancialStatementLevelRisk) {
            updateIsFinancialStatementExpanded(isExpanded)
        }

        if (!!isAssertionLevelRiskSection) {
            updateIsAssertionLevelRiskExpanded(isExpanded)
        }
    }, [isExpanded, isFinancialStatementLevelRisk, isAssertionLevelRiskSection])

    const redirectToRiskSummary = () => {
        navigate(`/project-management/${projectId}/risk-summary`);
    };

    const redirectToDeficiencySummary = () => {
        navigate(`/project-management/${projectId}/deficiency-summary`);
    };

    if (type === 'PROCEDURE') return (<ProcedureHeader section={section} />);
    return (
        <>
            {projectFormRisks.includes(id.toUpperCase()) && <RiskAlertIcon style={{ cursor: "pointer" }} onClick={() => redirectToRiskSummary()} />}
            {projectFormDeficiencies.includes(id.toUpperCase()) && <DeficiencyInfoIcon style={{ cursor: "pointer", width: '25px', height: '25px' }} onClick={() => redirectToDeficiencySummary()} />}
            {isAssertionLevelRiskSection && <a href={`#${allExpanded ? 'expand' : 'collapse'}`} className={styles.expand_all_link} onClick={() => { allExpanded ? collapseAll() : expandAll() }}>{allExpanded ? 'Collapse' : 'Expand'} All</a>}
            {isTrendingByMonthSection &&
                <GraphsManager
                    title={section.title}
                    projectId={projectId}
                    projectFormId={projectFormId}
                    sectionId={id}
                    schema={{
                        properties: schema,
                        formTypeId: formTypeId
                    }}
                    questionId={section?.fields[0]?.filter((field) => field.type === FIELD_TYPES.RADIOGROUP)[0]?.id}
                    trendingByMonthId={section?.fields[3]?.filter((field) => field.type === FIELD_TYPES.TRENDING_BY_MONTH)[0]?.id}
                    disabled={isProjectFinalized}
                />
            }
            {shouldDisplayEllipsis && <CLAActionEllipsis disabled={section.fields?.[0]?.[0]?.id === ID && isEllipseDisabled ? isEllipseDisabled : false}>
                {(handleClose) =>
                    menu.map((item) => (
                        <MenuItem
                            key={item.type}
                            onClick={() => {
                                if (section.fields[0][0].id === ID && item.type === ACTION_ELLIPSIS_TYPES.ADD_DEFICIENCY) {
                                    updateMyPresence({ focusedId: ID })
                                }
                                setModalType(item.type);
                                handleClose();
                            }}
                            variant="ellipsis-menu"
                            disabled={isProjectFinalized}
                        >
                            {item.label}
                        </MenuItem>
                    ))
                }
            </CLAActionEllipsis>}
        </>
    )
}
