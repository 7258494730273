import { userSummaryQuery } from '../users';
import { json } from "react-router-dom"
import projectFormServices from '@services/forms/projectforms.js';
import { fetchProjectAuthorization, fetchProject } from '../project';
import { defer } from "react-router-dom"
import { PROJECT_STATE } from "@constants/common";

const projectQuery = (id) => ({
    queryKey: [ "projectAuth", id],
    queryFn: async () => fetchProjectAuthorization(id),
    staleTime: 1000 * 60 * 5
})

const projectSimpleQuery = (id) => ({
    queryKey: ['PROJECT', 'SIMPLE', id],
    queryFn: () => fetchProject(id),
    staleTime: Infinity
})

export const loader = (queryClient) => async ({ request, params }) => {
    const url = new URL(request.url);
    if(url.pathname.includes("/create-new-project") && !url.pathname.includes("/edit")) {
        // Disable project authentication on create mode
        const hasForms = await projectFormServices.checkProjectHasForms(params?.projectId);
        if (!url.pathname.includes("/forms") && hasForms) {
            window.location.href = url.pathname + "/edit";   
        }
        
        return Promise.resolve()
    }

    if (url.pathname.includes("/project-dashboard")) {
        const psq = projectSimpleQuery(params.projectId)
        let projectSimple = queryClient?.getQueryData(psq.queryKey)
        if (!projectSimple) {
           projectSimple = await queryClient?.fetchQuery(psq) 
        }

        if (projectSimple && projectSimple.ProjectStateId === PROJECT_STATE.DRAFT)
            throw json({ data: 'Project Is Draft' }, { status: 403 });
    }
    let projectForm;
    try {
        if (params.projectFormId) {
            projectForm =
                await projectFormServices.getProjectFormWithSchemaById(
                    params.projectFormId, params.projectId
                );
            if (!projectForm) throw json({ data: 'Project Form Not Found' }, { status: 404 });
        }
        const query = userSummaryQuery()
        let summary = queryClient?.getQueryData(query.queryKey)
        if(!summary) {
            summary = await queryClient?.fetchQuery(query)
        }
        const pq = projectQuery(params.projectId, summary?.email)
        return queryClient?.getQueryData(pq) ?? defer({ data: queryClient.fetchQuery(pq), projectForm: projectForm })
    }
    catch(error) {
        throw error.response 
            ? {
                data: error.response.data,
                status: error.response.status,
                url: error.request.responseURL
            } 
            : error;
    }
}