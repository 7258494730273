import clientApi from '@utilities/claApiClient';
import formApi from '@utilities/claApiForm';
import { useQuery } from "@tanstack/react-query";

const getMethodologyVersions = async () => {
    try {
        const response = await clientApi.get('/methodology/versions');
        return { status: 200, data: response.data };
    } catch (error) {
        return { status: 400, message: error };
    }
};

const getMethodologyIndustries = async () => {
    try {
        const response = await clientApi.get('/methodology/industries');
        return { status: 200, data: response.data };
    } catch (ex) {
        return { status: 400, message: ex };
    }
};

const getMethodologyIndustriesByProjectId = async (projectId, isEnabledOnly = false) => {
    try {
        const response = await clientApi.get(`/methodology/industries/${projectId}`);

        if (isEnabledOnly) {
            const withIsEnabledOnlyData = response?.data.filter(mi => mi.IsEnabled) ?? [];
            return { status: 200, data: withIsEnabledOnlyData };
        }

        return { status: 200, data: response.data };
    } catch (ex) {
        return { status: 400, message: ex };
    }
}

const upsertDefaultRisks = async (methodologyVersionId, payload) => {
    try {
        const response = await formApi.put(
            `/methodology/${methodologyVersionId}/lists/default-risks`,
            payload
        );
        return { status: 200, data: response.data };
    } catch (error) {
        return { status: 400, message: error };
    }
};


const getServiceTypeConfigurationByMethodologyVersionId = async (methodologyVersionId) => {
    try {
        const response = await formApi.get(`/methodology/${methodologyVersionId}/service-type-configuration`);
        return { status: 200, data: response.data };
    } catch (error) {
        return { status: 400, message: error };
    }
}
const getDraftMethodology = async () => {
    try {
        const response = await formApi.get('/methodologyversion/draft');
        return { status: 200, data: response.data };
    } catch (error) {
        return { status: 400, message: error }; 
    }
}

const methodologyServices = {
    getMethodologyVersions,
    getMethodologyIndustries,
    getMethodologyIndustriesByProjectId,
    upsertDefaultRisks,
    getServiceTypeConfigurationByMethodologyVersionId,
    getDraftMethodology,
};

export default methodologyServices;

export const useGETMethodologyVersions = ({ isDraft = false }) => {
    const queryKey = isDraft ? ["DraftMethodologyVersions"] : ["MethodologyVersions"];
    const queryFn = isDraft ? getDraftMethodology : getMethodologyVersions;
    return useQuery(queryKey, queryFn, {
        refetchOnWindowFocus: false,
    });
}

export const useGETServiceTypeConfigurationByMethodologyVersionId = (methodologyId) => {
    return useQuery(
			["ServiceTypeConfigurations"],
			() => getServiceTypeConfigurationByMethodologyVersionId(methodologyId),
			{   
                enabled: !!methodologyId,
                select: (serviceTypeConfiguration) => {
                        return serviceTypeConfiguration.data[0];
                }
			}
    )
}