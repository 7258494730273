import { createContext, useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { GRAPHS_DRAWER } from "@constants/index";

import { useGetAllPeriodByProjectIdAndProjectFormIdQuery, useGetAllGraphByProjectIdAndProjectFormIdQuery } from "@services/planningAnalytics";

const GraphContext = createContext()

export const GraphContextProvider = ({ children }) => {
    const { projectId, projectFormId } = useParams();
    const { data: selectedPeriodsData } = useGetAllPeriodByProjectIdAndProjectFormIdQuery(projectId, projectFormId);
    const { data: selectedGraphsData } = useGetAllGraphByProjectIdAndProjectFormIdQuery(projectId, projectFormId);

    const [selectedGraphs, setSelectedGraphs] = useState([])
    const [selectedPriorPeriod, setSelectedPriorPeriod] = useState([])
    const [refetchComments, setRefetchComments] = useState(false);
    const [trialBalances, setTrialBalances] = useState();
    const [projectPlanningAnalyticPeriodHistorical, setProjectPlanningAnalyticPeriodHistorical] = useState([]);
    const [graphHistorical, setGraphHistorical] = useState([]);
    const [graphHighlighted, setGraphHighlighted] = useState(false)

    useEffect(() => {
        if (selectedPeriodsData) {
            const { Data: selectedPeriods, ProjectPlanningAnalyticPeriodHistorical } = selectedPeriodsData
            setProjectPlanningAnalyticPeriodHistorical(ProjectPlanningAnalyticPeriodHistorical)
            setSelectedPriorPeriod(selectedPeriods.map(data => ({
                projectPlanningAnalyticPeriodId: data?.ProjectPlanningAnalyticPeriodId,
                value: data?.PeriodValue,
                label: data?.PeriodLabel,
                color: data?.Color,
                code: data?.Code
            })));
        }
    }, [selectedPeriodsData])

    useEffect(() => {
        if (selectedGraphsData) {
            const { Data: selectedGraphs, ProjectPlanningAnalyticGraphHistorical } = selectedGraphsData
            setGraphHistorical(ProjectPlanningAnalyticGraphHistorical)
            setSelectedGraphs(selectedGraphs.map(data => ({
                projectPlanningAnalyticGraphId: data?.ProjectPlanningAnalyticGraphId,
                groupingId: data?.GroupingId,
                grouping: data?.GroupingName,
                generalLedgerId: data?.GeneralLedgerId,
                name: data?.GeneralLedgerName,
                trialBalanceId: data?.TrialBalanceId?.includes(GRAPHS_DRAWER.EN.DEFAULT_INCOME) ? GRAPHS_DRAWER.EN.DEFAULT_INCOME : data?.TrialBalanceId
            })));
        }
    }, [selectedGraphsData])

    return (
        <GraphContext.Provider value={{
            selectedGraphs,
            selectedPriorPeriod,
            refetchComments,
            trialBalances,
            setSelectedPriorPeriod,
            setSelectedGraphs,
            setRefetchComments,
            setTrialBalances,
            projectPlanningAnalyticPeriodHistorical,
            graphHistorical,
            graphHighlighted,
            actions: {
                handleGraphHighlight: setGraphHighlighted,
                handleProjectPlanningAnalyticPeriodHistorical: setProjectPlanningAnalyticPeriodHistorical,
                handleGraphHistorical: setGraphHistorical
            }
        }}>
            {children}
        </GraphContext.Provider>
    )
}

export const useGraphContext = () => {
    const ctx = useContext(GraphContext)
    if (!ctx) {
        throw new Error("`useGraphContext` must be used within GraphContextProvider")
    }
    return ctx
}